import Axios from 'axios'

const state = {
	pages: null,
	page: null,
}

const getters = {
	PAGES: state => state.pages,

	PAGE: state => state.page,
}

const mutations = {
	SET_PAGES: (state, payload) => {
		state.pages = payload
	},

	SET_PAGE_NAME: (state, payload) => {
		state.page = payload
	},

	SET_PAGE: (state, payload) => {
		if (state.page) {
			state.page[payload.page] = payload.data
		} else {
			state.page = {
				[payload.page]: payload.data,
			}
		}
	},
}

const actions = {
	GET_PAGES: async context => {
		await Axios({
			url: `${process.env.VUE_APP_API_VERSION}/page`,
			method: 'GET',
		})
			.then(response => {
				const obj = new Object()
				let counter = 0

				response.data.forEach(item => {
					counter++
					obj[item.alias] = state.page
						? state.page[item.alias]
							? state.page[item.alias]
							: null
						: null

					if (counter == response.data.length) {
						context.commit('SET_PAGE_NAME', obj)
					}
				})
				context.commit('SET_PAGES', response.data)
			})
			.catch(err => {
				console.log(err)
			})
	},

	GET_PAGE: async (context, payload) => {
		await Axios({
			url: `${process.env.VUE_APP_API_VERSION}/page/${payload}`,
			method: 'GET',
		})
			.then(response => {
				context.commit('SET_PAGE', { page: payload, data: response.data })
			})
			.catch(err => {
				console.log(err)
			})
	},

	REMOVE_PAGE: context => {
		context.commit('SET_PAGE', null)
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
