import Axios from 'axios'
import url from '../../utils/url'

const state = {
	slider: {
		holder: null,
		partner: null,
	},
}

const getters = {
	SLIDER: state => state.slider,
}

const mutations = {
	SET_SLIDER: (state, payload) => {
		const category = payload.category == 1 ? 'holder' : 'partner'

		if (payload.data.length > 0) {
			state.slider[category] = payload.data
		} else {
			state.slider[category] = null
		}
	},
}

const actions = {
	GET_SLIDER: async (context, payload) => {
		await Axios({
			url: `${process.env.VUE_APP_API_VERSION}/slider?category=${payload.category}`,
			method: 'GET',
		})
			.then(response => {
				response.data.description = url.addTargeBlankToExternal(
					response.data.description
				)

				context.commit('SET_SLIDER', {
					data: response.data,
					category: payload.category,
				})
			})
			.catch(err => {
				console.log(err)
			})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
