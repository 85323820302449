require('es6-promise').polyfill()
require('es6-promise/auto')

import 'core-js/stable'
import 'regenerator-runtime/runtime'

import 'vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css'
import '@/styles/style.less'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import PerfectScrollbar from 'vue2-perfect-scrollbar'
import moment from 'moment'
import Vuelidate from 'vuelidate'
import Toasted from 'vue-toasted'
import { VueMaskDirective } from 'v-mask'

import { VTooltip, VPopover, VClosePopover } from 'v-tooltip'
import Vue2TouchEvents from 'vue2-touch-events'
import VueMeta from 'vue-meta'
import VueCookies from 'vue-cookies'
Vue.use(VueMeta, {
	// optional pluginOptions
	refreshOnceOnNavigation: true
  })
Vue.directive('mask', VueMaskDirective, {
	placeholders: {
		'#': null, // passing `null` removes default placeholder, so `#` is treated as character
		D: /\d/, // define new placeholder
		R: /[\wа-яА-Я]/, // cyrillic letter as a placeholder
	},
})
Vue.directive('tooltip', VTooltip)
Vue.directive('close-popover', VClosePopover)
Vue.component('v-popover', VPopover)

Vue.use(Vuelidate)
Vue.use(PerfectScrollbar)
Vue.use(Toasted, {singleton: true})
Vue.use(Vue2TouchEvents)
Vue.use(VueCookies)
Vue.prototype.$moment = moment

Vue.config.productionTip = false

Vue.directive('click-outside', {
	bind: function(
		el,
		binding
		// vNode
	) {
		// if (typeof binding.value !== 'function') {
		// 	const compName = vNode.context.name
		// 	let warn = `[Vue-click-outside:] provided expression '${binding.expression}' is not a function, but has to be`
		// 	if (compName) {
		// 		warn += `Found in component '${compName}'`
		// 	}

		// 	console.warn(warn)
		// }
		const bubble = binding.modifiers.bubble
		const handler = e => {
			if (bubble || (!el.contains(e.target) && el !== e.target)) {
				binding.value(e)
			}
		}
		el.__vueClickOutside__ = handler

		document.addEventListener('click', handler)
	},

	unbind: function(el) {
		document.removeEventListener('click', el.__vueClickOutside__)
		el.__vueClickOutside__ = null
	},
})

new Vue({
	router,
	store,
	render: h => h(App),
	mounted() {
		// You'll need this for renderAfterDocumentEvent.
		document.dispatchEvent(new Event('render-event'))
	},
}).$mount('#app')
