import Axios from 'axios'
import filter from '../../utils/filter'

const state = {
	pointsOrganizationGrouped: [],
	pointsOrganization: null,
	userCoordinates: null,
}

const getters = {
	POINTS_ORGANIZATION_GROUPED: state => state.pointsOrganizationGrouped,
	POINTS_ORGANIZATION: state => state.pointsOrganization,
	USER_COORDINATES: state => state.userCoordinates,
}

const mutations = {
	SET_POINTS_ORGANIZATION_GROUP:(state, payload) => {
		state.pointsOrganizationGrouped = payload
	},
	SET_POINTS_ORGANIZATION: (state, payload) => {
		state.pointsOrganization = payload
	},
	SET_USER_COORDINATES: (state, payload) => {
		state.userCoordinates = payload
	},
}

const actions = {
	GET_POINTS_ORGANIZATION: async context => {
		await Axios({
			url: `${process.env.VUE_APP_API_ESK}/IssuePointOrganizations`,
			method: 'GET',
			headers: {
				Authorization: process.env.VUE_APP_AUTH_ESK,
			},
		})
			.then(response => {
				const data = response.data
				//const city = JSON.parse(localStorage.getItem('pickedCity'))
				let arr = new Array()
				let counter = 0
				const filteredFilters = filter.sortingFilter(response.data)
				context.commit('SET_POINT_LIST_FILTERS', filteredFilters)

				data.forEach(point => {
					context
						.dispatch('GET_POINT_ORGANIZATION', point.id)
						.then(response => {
							counter++

							if (response.data?.length) {
								const organization = (response.data[0])?.organization
								const children = []
								response.data
									.filter(el => !!(el.id && el.geolocation))
									.forEach(el => {
										if (!children.some(child => child.id === el.id))
											children.push({
												...el,
												icon: {
													layout: 'default#imageWithContent',
													imageHref: organization.iconUrl,
													imageSize: [70, 70],
													imageOffset: [-35, -70],
												}
											})
									})
								arr.push({
									organization,
									children
								})
							}

							if (counter == data.length) {
								context.commit('SET_POINTS_ORGANIZATION_GROUP', arr)
							}
						})
				})
			})
			.catch(err => {
				console.log(err)
			})
	},
	GET_POINT_ORGANIZATION: async (context, payload) => {
		try {
			const response = await Axios({
				url: `${process.env.VUE_APP_API_ESK}/IssuePoints?uid=${payload}`,
				method: 'GET',
				headers: {
					Authorization: process.env.VUE_APP_AUTH_ESK,
				},
			})
			// хардкод для МФЦ:
			if (payload === 4670 && response.data && response.data.length) {
				response.data.forEach(resp => {
					if (resp.organization) {
						resp.organization.isBankCardIssuer = true
						resp.organization.isNonFinancialCardIssuer = false
					}
				})
			}
			// конец хардкода для МФЦ
			return response
		} catch {
			return null
		}
	},
	SET_USER_COORDINATES: async (context, payload) => {
		context.commit('SET_USER_COORDINATES', payload)
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
