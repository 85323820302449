import Axios from 'axios'
import url from '../../utils/url'

const state = {
	news: {
		slider: null,
		list: null,
		pageCount: null,
		currentPage: null,
	},
	singleNews: null,
}

const getters = {
	NEWS: state => state.news,

	SINGLE_NEWS: state => state.singleNews,
}

const mutations = {
	SET_NEWS: (state, payload) => {
		if (payload.refresh) {
			state.news[payload.type] = payload.data
		} else {
			state.news[payload.type] = state.news[payload.type].concat(payload.data)
		}

		if (payload.type != 'slider') {
			state.news.pageCount = parseInt(payload.pageCount)
			state.news.currentPage = parseInt(payload.currentPage)
		}
	},

	SET_SINGLE_NEWS: (state, payload) => {
		state.singleNews = payload
	},
}

const actions = {
	GET_NEWS: async (context, payload) => {
		const currentPage = payload.page || 1
		const perPage = payload.perPage || 4
		const categories = context.getters.CATEGORIES
		const date = payload.date
			? `&from=${payload.date.from}&to=${payload.date.to}`
			: ''
		let type = ''

		if (payload.holder) {
			type = categories ? categories.holder : 1
		} else if (payload.partner) {
			type = categories ? categories.partner : 2
		} else if (payload.operator) {
			type = categories ? categories.operator : 4
		}
		// await Axios({
		// 	url: `${process.env.VUE_APP_API_VERSION}/news?category=${type}&page=${currentPage}&per-page=${perPage}&sort=-created_at${date}`,
		// 	method: 'GET',
		// })

		await Axios.get(
			`${process.env.VUE_APP_API_VERSION}/news?category=${type}&page=${currentPage}&per-page=${perPage}&sort=-created_at${date}`
		)
			.then(response => {
				response.data.description = url.addTargeBlankToExternal(
					response.data.description
				)
				response.data.text = url.addTargeBlankToExternal(response.data.text)
				context.commit('SET_NEWS', {
					type: payload.type,
					data: response.data,
					pageCount: response.headers['x-pagination-page-count'],
					currentPage: response.headers['x-pagination-current-page'],
					refresh: payload.refresh,
				})
			})
			.catch(err => {
				console.log(err)
			})
	},

	GET_SINGLE_NEWS: async (context, payload) => {
		const categories = context.getters.CATEGORIES
		let type = ''

		if (payload.holder) {
			type = categories ? categories.holder : 1
		} else if (payload.partner) {
			type = categories ? categories.partner : 2
		} else if (payload.operator) {
			type = categories ? categories.operator : 4
		}
		// await Axios({
		// 	url: `${process.env.VUE_APP_API_VERSION}/news/${payload.id}?category=${type}`,
		// 	method: 'GET',
		// })

		await Axios.get(
			`${process.env.VUE_APP_API_VERSION}/news/${payload.id}?category=${type}`
		)
			.then(response => {
				response.data.current.description = url.addTargeBlankToExternal(
					response.data.current.description
				)
				response.data.current.text = url.addTargeBlankToExternal(
					response.data.current.text
				)
				context.commit('SET_SINGLE_NEWS', response.data)
			})
			.catch(err => {
				console.log(err.response)
				context.commit('SET_SINGLE_NEWS', err.response.data)
			})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
