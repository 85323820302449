import Vue from 'vue'
import Vuex from 'vuex'

// events
import modal from './events/modal'
import blockScroll from './events/blockScroll'
import visuallyImpaired from './events/visuallyImpaired'
import cookies from './events/cookies'

// actions
import cities from './actions/cities'
import listAcceptorCategories from './actions/listAcceptorCategories'
import pointFilters from './actions/pointFilters'
import pointOrganization from './actions/pointOrganization'
import loyaltyPrograms from './actions/loyaltyPrograms'
import acceptors from './actions/acceptors'
import keyPartners from './actions/keyPartners'

import setting from './actions/setting'
import blocks from './actions/blocks'
import slider from './actions/slider'
import dynamicPages from './actions/dynamicPages'
import news from './actions/news'
import videos from './actions/video'
import faq from './actions/faq'
import materials from './actions/materials'

import feedback from './actions/feedback'
import requestCard from './actions/requestCard'

import search from './actions/search'

import dadata from './actions/dadata'
//import createPersistedState from "vuex-persistedstate"
Vue.use(Vuex)

export default new Vuex.Store({
	//plugins: [createPersistedState()],
	modules: {
		modal,
		blockScroll,
		visuallyImpaired,
		cookies,

		cities,
		listAcceptorCategories,
		pointFilters,
		pointOrganization,
		loyaltyPrograms,
		acceptors,
		keyPartners,

		setting,
		blocks,
		slider,
		dynamicPages,
		faq,
		news,
		videos,
		materials,

		feedback,
		requestCard,

		search,

		dadata,
	},
})
