<template>
	<li class="footer__item" v-if="data">
		<h3 class="footer__title" @click="showLinks" :class="{ active: show }">
			{{ data.title }}
		</h3>
		<ul class="footer__sub-list" :class="{ active: show }">
			<li
				class="footer__sub-item"
				v-for="(link, index) in data.links"
				:key="index"
			>
				<router-link :to="link.path" class="footer__link">
					{{ link.name }}
				</router-link>
			</li>
		</ul>
	</li>
</template>

<script>
export default {
	name: 'FooterItem',

	props: {
		data: {
			type: Object,
			required: true,
		},
	},

	data() {
		return {
			show: false,
		}
	},

	methods: {
		showLinks() {
			this.show ? (this.show = false) : (this.show = true)
		},
	},
}
</script>
