import cookie from 'vue-cookies'

const state = {
  cookiesAccepted: cookie.get('eskso_cc')
}

const getters = {
  COOKIES: state => state.cookiesAccepted
}

const mutations = {
  SET_COOKIES: (state, payload) => {
    if (payload) {
      state.cookiesAccepted = payload
    }
  }
}

const actions = {
  ACCEPT_COOKIES: (context) => {
    context.commit('SET_COOKIES', true)
  },
}

export default {
  state, 
  getters,
  mutations,
  actions,
}