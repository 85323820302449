<template>
	<header
		class="header"
		:class="[{ 'header--small': smallView }, { active: mobileMenuShow }]"
		v-if="mainSite"
	>
		<button class="header__mobile-close" @click="closeMenu">
			<span class="visually-hidden">Закрыть меню</span>
		</button>

		<HeaderVisuallyImpairedBlock
			v-if="visuallyImpaired"
			:class="[
				{ 'visually-impaired--small': smallView },
				{ active: visuallyImpairedShow },
			]"
		></HeaderVisuallyImpairedBlock>

		<div class="container">
			<div class="header__wrapper">
				<div class="header__logo-wrapper" id="header__logo">
					<router-link :to="{ name: 'HolderHome' }">
						<span class="visually-hidden">Главная страница</span>
<img 
							alt="eskso.ru"
							width="167"
							class="header__logo"
:src="logoImg"/>
					</router-link>
				</div>
				<div class="header__box">
					<transition name="header-top-slide">
						<div class="header__list-wrapper" v-show="!smallView">
							<ul class="header__list header__mobile-hide">
								<li class="header__item">
									<a
										v-if="getSetting && getSetting.phone"
										:href="`tel:${getSetting.phone.value}`"
										class="header__link"
										@click="phoneClick"
									>
										<span class="icon icon--phone"></span>
										{{ getSetting.phone.value }}
									</a>
								</li>
								<li class="header__item">
									<button @click="showModalFeedback" class="header__link">
										<span class="icon icon--headphones"></span>
										Обратная связь
									</button>
								</li>
								<li class="header__item header__desktop">
									<button @click="switchdVisuallyImpaired" class="header__link">
										<span class="icon icon--eye"></span>
										{{ visuallyImpairedText }}
									</button>
								</li>
							</ul>

							<button
								@click.prevent="showModalCityes"
								class="header__link header__link--underline header__desktop"
								v-if="getCity"
								style="position: relative"
							>
								<span class="icon icon--map-pin"></span>
								{{ getCity.name }}
							</button>
							<span class="header__change-city" v-if="!myCity" :class="smallView ? 'header__monile-popup': ''">
								<span v-if="getCity">Ваш город {{ getCity.name }}?</span>
								<p>
									<button
										class="button"
										@click="applyMyCity(getCity)"
									>
										Да
									</button>
									<span class="other-city" @click="showModalCityes"
										>Другой город</span
									>
								</p>
							</span>
							<ul
								class="header__list header__desktop"
								v-if="linkUserType.length > 0"
							>
								<HeaderItem
									class="header__item"
									:data="link"
									v-for="(link, index) in linkUserType"
									:key="index"
									@click="closeMenu"
								></HeaderItem>
							</ul>

							<button class="button-burger header__tablet" @click="openMenu">
								Меню
							</button>
						</div>
					</transition>
					<div class="header__list-wrapper header__mobile-nav">
						<div class="flex--middle header__tablet">
							<ul
								class="header__list"
								@click="closeMenu"
								v-if="linkUserType.length > 0"
							>
								<HeaderItem
									class="header__item"
									:data="link"
									v-for="(link, index) in linkUserType"
									:key="index"
								></HeaderItem>
							</ul>
							<button class="header__close" @click="closeMenu">
								<span class="visually-hidden">Закрыть меню</span>
							</button>
						</div>

						<Search class="header__tablet" ref="search"></Search>

						<transition name="header-nav">
							<div class="flex flex--between" v-show="!activeSearch">
								<nav class="header__nav nav">
									<ul class="nav__list" @click="closeMenu" v-if="setLinks">
										<HeaderItem
											:data="link"
											v-for="(link, index) in setLinks"
											:key="index"
										>
										</HeaderItem>
									</ul>
								</nav>
							</div>
						</transition>
						<ul class="header__list" :class="{ 'flex--grow': activeSearch }">
							<li v-show="!activeSearch">
								<transition name="header-small-list">
									<ul
										class="header__list header__list--no-borders header__desktop"
										v-show="smallView"
									>
										<li class="header__item">
											<a
												v-if="getSetting && getSetting.phone"
												:href="`tel:${getSetting.phone.value}`"
												class="header__link header__link--small"
												v-tooltip.bottom-end="getSetting.phone.value"
											>
												<span class="icon icon--phone"></span>
												<span class="visually-hidden">
													Позвонить по номеру
													{{ getSetting.phone.value }}
												</span>
											</a>
										</li>
										<li class="header__item">
											<button
												@click="showModalFeedback"
												class="header__link header__link--small"
												v-tooltip.bottom-end="'Обратная связь'"
											>
												<span class="icon icon--headphones"></span>
												<span class="visually-hidden">Обратная связь</span>
											</button>
										</li>
										<li class="header__item">
											<button
												@click="showModalCityes"
												class="header__link header__link--small"
												v-tooltip.bottom-end="'Выбор города'"
											>
												<span class="icon icon--map-pin"></span>
												<span class="visually-hidden">Выбор города</span>
											</button>
										</li>
									</ul>
								</transition>
							</li>
							<li
								class="header__item flex header__desktop"
								:class="{ 'flex--grow': activeSearch }"
								v-click-outside="hideSearch"
							>
								<button
									class="header__button-search button"
									:class="{ 'visually-hidden': activeSearch }"
									@click="showSearch"
								>
									<span class="visually-hidden">Открыть поиск</span>
									<span class="icon icon--search"></span>
								</button>
								<transition name="header-search-grow">
									<Search v-if="activeSearch" ref="search"></Search>
								</transition>
							</li>
							<!-- <li class="header__item">
								<router-link class="button" :to="{ name: 'Login' }"
									>Личный кабинет</router-link
								>
							</li>-->
							<li class="header__item header__item--lk">
								<a :href="`${externalLkUrl}`" class="button">Личный кабинет</a>
							</li>
						</ul>
						<div class="header__mobile-bottom">
							<button
								@click="showModalCityes"
								@click.prevent
								class="header__link"
								v-if="getCity"
							>
								<span class="icon icon--map-pin"></span>
								{{ getCity.name }}
							</button>
							<button
								class="header__link header__link--impaired"
								@click="switchdVisuallyImpaired"
							>
								<span class="icon icon--eye"></span>
								{{ visuallyImpairedText }}
							</button>
							<button
								v-if="visuallyImpaired"
								class="header__link header__link--mobile-hide"
								@click="visuallyImpairedShowToggle"
							>
								<span class="icon icon--eye"></span>
								Открыть панель настроек
							</button>
							<a
								v-if="getSetting && getSetting.phone"
								:href="`tel:${getSetting.phone.value}`"
								class="header__link header__mobile"
							>
								<span class="icon icon--phone"></span>{{ getSetting.phone.value }}
							</a>
							<button
								@click="showModalFeedback"
								class="header__link header__mobile"
							>
								<span class="icon icon--headphones"></span>Обратная связь
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</header>
</template>

<script>
import HeaderVisuallyImpairedBlock from '@/components/HeaderVisuallyImpairedBlock.vue'
import Search from '@/components/Search.vue'
import HeaderItem from '@/components/HeaderItem.vue'
//import Logo from '@/assets/images/logos/logo_new.svg'
export default {
	name: 'Header',

	components: {
		HeaderVisuallyImpairedBlock,
		Search,
		HeaderItem,
		//Logo,
	},

	data() {
		return {
			externalLkUrl: null,
			lkUrlProd: process.env.VUE_APP_EXTENAL_LK_URL_PROD,
			lkUrlTest: process.env.VUE_APP_EXTENAL_LK_URL_TEST,
			logoImg: require('@/assets/images/logos/logo_new.svg'),
			getGeo1: {},
			myCity: null,
			//showAnotherCity: true,
			activeSearch: false,
			scrollTop: 0,
			smallView: false,
			mobileMenuShow: false,
			visuallyImpaired: false,
			visuallyImpairedShow: false,
			visuallyImpairedText: 'Версия для слабовидящих',
			/*
					{
						item: { name: 'Support' },
						title: 'Поддержка',
						menu: [
							{
								title: "Часто задаваемые вопросы",
								item: { name: "Questions" }
							}
						]
					},
			*/
			linkUserType: [
				{
					item: { name: 'HolderHome' },
					title: 'Держателю',
					menu: [],
				},
				{
					item: { name: 'PartnerHome' },
					title: 'Партнёру',
					menu: [],
				},
				/*
				{
					path: 'HolderHome',
					name: 'Держателю',
				},
				{
					path: 'PartnerHome',
					name: 'Партнёру',
				},
				*/
			],
		}
	},

	methods: {
		applyMyCity(city){
			localStorage.setItem('pickedCity', JSON.stringify(city))
			this.myCity = city
			//this.showAnotherCity = false
		},
		showSearch() {
			this.activeSearch = true

			setTimeout(() => {
				this.$refs.search.$refs.search.focus()
			}, 200)
		},

		hideSearch() {
			this.activeSearch = false
		},

		onScroll() {
			if (
				window.pageYOffset >= 20 ||
				document.documentElement.scrollTop >= 20
			) {
				this.smallView = true
			} else {
				this.smallView = false
			}
		},

		openMenu() {
			this.mobileMenuShow = true
			this.visuallyImpairedShow = false

			this.$store.dispatch('SET_BLOCK_SCROLL', true)
		},

		closeMenu() {
			this.mobileMenuShow = false
			this.$store.dispatch('SET_BLOCK_SCROLL', false)
		},

		showModalCityes() {
			this.$store.dispatch('GET_MODAL', { type: 1 })
			this.mobileMenuShow = false
			this.myCity = true
			//this.showAnotherCity = false
		},

		phoneClick() {
			/* */
		},

		showModalFeedback() {
			this.$store.dispatch('GET_MODAL', { type: 2 })
			this.mobileMenuShow = false
			this.$metrika.reachGoal('feedbackForm')
		},

		switchdVisuallyImpaired() {
			if (!this.visuallyImpaired) {
				this.visuallyImpaired = true
				this.visuallyImpairedText = 'Обычная версия'
				this.$store.dispatch('SET_VISUALLY_IMPAIRED', 1)
			} else {
				this.visuallyImpaired = false
				this.visuallyImpairedText = 'Версия для слабовидящих'
				this.$store.dispatch('SET_VISUALLY_IMPAIRED', 0)
			}
		},

		visuallyImpairedShowToggle() {
			if (this.visuallyImpairedShow) {
				this.visuallyImpairedShow = false
				this.$store.dispatch('SET_BLOCK_SCROLL', false)
			} else {
				this.visuallyImpairedShow = true
				this.mobileMenuShow = false
				this.$store.dispatch('SET_BLOCK_SCROLL', true)
			}
		},
	},

	computed: {
		getCity() {
			return this.$store.getters.PICKEDCITY
		},
		getGeo() {
			return this.$store.getters.GEO
		},
		mainSite() {
			const meta = this.$route.meta

			if (meta.holder || meta.partner || meta.operator) {
				return true
			} else {
				return false
			}
		},

		setLinks() {
			const meta = this.$route.meta
			let links = null

			if (meta.holder) {
				links = [
					{
						item: { name: 'Capabilities' },
						title: 'Возможности',
						menu: [
							{
								title: 'Расчетное приложение',
								item: { name: 'SettlementApplication' },
							},
							{
								title: 'Скидки и бонусы',
								item: { name: 'LoyaltyProgram' },
							},
							{
								title: 'Транспортное приложение',
								item: { name: 'TransportApplication' },
							},
							{
								title: 'Мобильное приложение',
								item: '/holder/capabilities/mobyle-application',
							},
						],
					},
					{
						item: { name: 'HowToGetACard' },
						title: 'Как получить карту',
						menu: [
							{
								title: 'Банковская карта',
								item: { name: 'CardBank' },
							},
							{
								title: 'Нефинансовая карта',
								item: { name: 'CardSocial' },
							},
						],
					},
					{
						item: { name: 'Materials' },
						title: 'Материалы',
						menu: [
							{
								title: 'Нормативно-правовая база',
								item: { name: 'LegalFramework' },
							},
							{
								title: 'Руководства и регламенты',
								item: { name: 'GuidesAndRegulations' },
							},
						],
					},
					{
						item: { name: 'Support' },
						title: 'Поддержка',
						menu: [
							{
								title: 'Часто задаваемые вопросы',
								item: { name: 'Questions' },
							},
						],
					},
					{
						item: { name: 'NewsHolder' },
						title: 'Новости',
					},
					{
						item: { name: 'Partners' },
						title: 'Партнеры и акции',
					},
				]
			} else if (meta.partner) {
				links = [
					{
						item: { name: 'Advantages' },
						title: 'Преимущества',
						menu: [],
					},
					{
						item: { name: 'JoiningProcedure' }, 
						title: 'Порядок присоединения',
						menu: [],
					},
					{
						item: { name: 'PartnerMaterials' },
						title: 'Материалы',
						menu: [
								{
									title: 'Нормативно-правовая база',
									item: { name: 'LegalFrameworkPartner' },
								},
								{
									title: 'Руководства и регламенты',
									item: { name: 'GuidesAndRegulationsPartner' },
								},
                                {
                                	title: 'Руководства и регламенты по предоставлению МСП на транспорте по ЕСК',
									item: '/partner/materials/transport',
								},
                        ],
					},
					{
						item: { name: 'NewsPartner' },
						title: 'Новости',
					},
					{
						item: { name: 'ContactsPartner' },
						title: 'Контакты',
						menu: [],
					},
				]
			} else if (meta.operator) {
				links = [
					{
						path: 'OperatorInformation',
						name: 'Информация об операторе',
						menu: [],
					},
					{
						path: 'Cooperation',
						name: 'Сотрудничество',
						menu: [],
					},
					{
						path: 'Contacts',
						name: 'Контакты',
						menu: [],
					},
				]
			}
			return links
		},

		getSetting() {
			return this.$store.getters.SETTING
		},
	},

	mounted() {
		window.addEventListener('scroll', this.onScroll)
		this.popupItem = this.$el

		this.$root.$on('hideVisuallyImpaired', () => {
			this.visuallyImpairedShowToggle()
		})

		this.$root.$on('openMenu', () => {
			this.openMenu()
		})
		this.$root.$on('closeMenu', () => {
			this.closeMenu()
		})
		const visuallyImpaired = this.$store.getters.VISUALLY_IMPAIRED

		if (visuallyImpaired) {
			this.visuallyImpaired = true
			this.visuallyImpairedText = 'Обычная версия'
		}

		this.myCity = localStorage.getItem('pickedCity')
		if(this.myCity != null && this.myCity != undefined){
			this.myCity = JSON.parse(this.myCity)
		}

		let lkUrlProd = process.env.VUE_APP_EXTERNAL_LK_URL_PROD
		let lkUrlTest = process.env.VUE_APP_EXTERNAL_LK_URL_TEST
		let currentUrl = window.location.href
		
		if(currentUrl.includes('test')){
			this.externalLkUrl = lkUrlTest
		}else {
			this.externalLkUrl = lkUrlProd
		}
	},
	beforeDestroy() {
		window.removeEventListener('scroll', this.onScroll)
	},
}
</script>
