<template>
	<form class="header__form" @submit.prevent="search">
		<label>
			<span class="visually-hidden">Поиск по сайту</span>
			<input
				type="text"
				class="header__input"
				placeholder="Поиск"
				ref="search"
				v-model.trim="str"
			/>
		</label>
		<button type="submit" class="header__form-button button" @click="search">Найти</button>
	</form>
</template>

<script>
export default {
	name: 'SearchInput',

	data() {
		return {
			str: null,
		}
	},
	methods: {
		isMobile() {
			return window.innerWidth < 1024 ? true : false
		},
		search() {
			const currentPathName = this.$route.name
			const minCharacters = 3
			const setting = {
				theme: 'toasted-primary',
				position: 'top-right',
				duration: 1000,
				keepOnHover: true,
			}
			const meta = this.$route.meta
			let link = null

			if (meta.holder) {
				link = 'HolderSearch'
			} else if (meta.partner) {
				link = 'PartnerSearch'
			} else if (meta.operator) {
				link = 'OperatorSearch'
			}

			if (this.str && this.str.length >= minCharacters) {
				this.$store.dispatch('GET_SEARCH', {
					query: this.str,
					currentPage: 1,
					perPage: 10,
					refresh: true,
				})
				if (currentPathName != link) {
					this.$router.push({ name: link }).catch(error => {
						console.info(error.message)
					})
				}
				this.$root.$emit('closeMenu')
			} else {
				this.$toasted.show(
					`Минимальное количество символов для поиска: ${minCharacters}`,
					{
						...setting,
						type: 'error',
					}
				)
			}
			setTimeout(()=>{
				if(this.isMobile()) this.str = ''
			}, 500)
		},
	},
}
</script>
