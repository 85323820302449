const url = {}
url.clearWwwHttpFromUrl = function(url){
	let newUrl = url?.replace(/(^\w+:|^)\/\//, '')?.replace('www.', '')
	return newUrl ? newUrl : '';
}
url.isExternal = function(link) {
    var domain = function(link) {
		return link
			.replace('http://', '')
			.replace('https://', '')
			.replace('//', '')
			.split('/')[0]
	}
    return domain(location.href) !== domain(link)
}

url.replaceAllLinks = function(html, link) {
	let regex = /href\s*=\s*(['"])(https?:\/\/.+?)\1/gi
	let l
	while ((l = regex.exec(html)) !== null) {
		html = html.replace(l[2], link)
    }
	return html
}

url.addTargeBlankToExternal = function(html) {
	let regex = /href\s*=\s*(['"])((https|http)?:\/\/.+?)\1/gi
	let link
	while ((link = regex.exec(html)) !== null) {
		if (url.isExternal(link[2])) {
			html = html.replace(link[2] + '"', link[2] + '" target="__blank"')
      }
    }
	return html
}
url.clearEndSlash = function(url)     
{     
    return url.replace(/\/$/, "");
} 
export default url