import Vue from 'vue'
import VueRouter from 'vue-router'
import VueYandexMetrics from 'vue-yandex-metrika'
//import url from '../utils/url'
Vue.use(VueRouter)

const routes = [
	// {
	// 	path: '/404',
	// 	name: 'ErrorPage',
	// 	component: () => import('../views/ErrorPage.vue'),
	// 	meta: {
	// 		title: 'Страница не найдена',
	// 		errorPage: true,
	// 	},
	// },
	{
		path: '*',
		name: 'ErrorPage',
		component: () => import('../views/ErrorPage.vue'),
		meta: {
			title: 'Страница не найдена',
			errorPage: true,
		},
	},
	{
		path: '/',
		redirect: {
			name: 'HolderHome',
		},
	},
	{
		path: '/holder',
		//pathToRegexpOptions: { strict: true },
		component: () => import('../views/holder/Main.vue'),
		children: [
			{
				path: '',
				name: 'HolderHome',
				component: () => import('../views/holder/pages/Home.vue'),
				meta: {
					title: 'Единая социальная карта Свердловской области',
					holder: true,
				},
			},
			{
				path: 'capabilities',
				component: () => import('../views/holder/pages/Capabilities/Main.vue'),
				children: [
					{
						path: '',
						name: 'Capabilities',
						component: () =>
							import('../views/holder/pages/Capabilities/Capabilities.vue'),
						meta: {
							title: 'Возможности карты',
							holder: true,
						},
					},
					{
						path: 'settlement-application',
						name: 'SettlementApplication',
						component: () =>
							import(
								'../views/holder/pages/Capabilities/SettlementApplication.vue'
							),
						meta: {
							title: 'Расчетное приложение',
							holder: true,
						},
					},
					{
						path: 'transport-application',
						//pathToRegexpOptions: { strict: true },
						name: 'TransportApplication',
						component: () =>
							import(
								'../views/holder/pages/Capabilities/TransportApplication.vue'
							),
						meta: {
							title: 'Транспортное приложение',
							holder: true,
						},
					},
					{
						path: 'loyalty-program',
						name: 'LoyaltyProgram',
						component: () =>
							import('../views/holder/pages/Capabilities/LoyaltyProgram.vue'),
						meta: {
							title: 'Скидки и бонусы',
							holder: true,
						},
					},
					{
						path: ':alias',
						name: 'CapabilitiesPages',
						component: () =>
							import('../views/holder/pages/Capabilities/CapabilitiesPage.vue'),
						props: true,
						meta: {
							title:
								'Согласие посетителя сайта на обработку персональных данных',
							holder: true,
						},
					},
					/*
					{
						path: ':alias',
						name: 'CapabilitiesPages',
						component: () =>
							import('../views/holder/pages/Capabilities/CapabilitiesPage.vue'),
						props: true,
						meta: {
							title: 'Возможности карты',
							holder: true,
						},
					},
					*/
					{
						path: 'partner/:id',
						name: 'CapabilitiesProgram',
						component: () =>
							import('../views/holder/pages/Capabilities/Partner.vue'),
						props: true,
						meta: {
							title: '',
							holder: true,
						},
					},
					{
						path: '*',
						redirect: {
							name: 'ErrorPage',
						},
					},
				],
			},
			{
				path: 'how-to-get-a-card',
				name: 'HowToGetACard',
				component: () => import('../views/holder/pages/HowToGetACard.vue'),
				meta: {
					title: 'Как получить карту',
					holder: true,
				},
			},
			{
				path: 'card-bank',
				name: 'CardBank',
				component: () =>
					import('../views/holder/pages/Capabilities/CardBank.vue'),
				meta: {
					title: 'Банковская карта',
					holder: true,
				},
			},
			{
				path: 'card-social',
				name: 'CardSocial',
				component: () =>
					import('../views/holder/pages/Capabilities/CardSocial.vue'),
				meta: {
					title: 'Нефинансовая карта',
					holder: true,
				},
			},
			{
				path: 'materials',
				name: '',
				component: () => import('../views/holder/pages/Materials/Main.vue'),
				children: [
					{
						path: '',
						name: 'Materials',
						component: () =>
							import('../views/holder/pages/Materials/Materials.vue'),
						meta: {
							title: 'Материалы',
							holder: true,
						},
					},
					{
						path: 'legal-framework',
						name: 'LegalFramework',
						component: () =>
							import('../views/holder/pages/Materials/LegalFramework.vue'),
						meta: {
							title: 'Нормативно-правовая база проекта',
							holder: true,
						},
					},
					{
						path: 'guides-and-regulations',
						name: 'GuidesAndRegulations',
						component: () =>
							import(
								'../views/holder/pages/Materials/GuidesAndRegulations.vue'
							),
						meta: {
							title: 'Руководства и регламенты',
							holder: true,
						},
					},
					{
						path: '*',
						redirect: {
							name: 'ErrorPage',
						},
					},
				],
			},
			{
				path: 'support',
				component: () => import('../views/holder/pages/Support/Main.vue'),
				children: [
					{
						path: '',
						name: 'Support',
						component: () =>
							import('../views/holder/pages/Support/Support.vue'),
						meta: {
							title: 'Поддержка',
							holder: true,
						},
					},
					{
						path: 'questions',
						name: 'Questions',
						component: () =>
							import('../views/holder/pages/Support/Questions.vue'),
						meta: {
							title: 'Часто задаваемые вопросы',
							holder: true,
						},
					},
					{
						path: '*',
						redirect: {
							name: 'ErrorPage',
						},
					},
				],
			},
			{
				path: 'news',
				component: () => import('../views/News/Main.vue'),
				children: [
					{
						path: '',
						name: 'NewsHolder',
						component: () => import('../views/News/News.vue'),
						meta: {
							title: 'Последние новости',
							holder: true,
						},
					},
					{
						path: ':id',
						name: 'NewsItemHolder',
						component: () => import('../views/News/NewsItem.vue'),
						props: true,
						meta: {
							title: 'Последние новости',
							holder: true,
						},
					},
					{
						path: '*',
						redirect: {
							name: 'ErrorPage',
						},
					},
				],
			},
			{
				path: 'search',
				component: () => import('../views/search/Main.vue'),
				children: [
					{
						path: '',
						name: 'HolderSearch',
						component: () => import('../views/search/Search.vue'),
						meta: {
							title: 'Поиск',
							holder: true,
						},
					},
					{
						path: '*',
						redirect: {
							name: 'ErrorPage',
						},
					},
				],
			},
			{
				path: 'stocks',
				component: () => import('../views/holder/pages/Stocks/Main.vue'),
				children: [
					{
						path: '',
						name: 'Stocks',
						component: () => import('../views/holder/pages/Stocks/Stocks.vue'),
						meta: {
							title: 'Акции',
							holder: true,
						},
					},
					{
						path: ':id',
						name: 'StocksItem',
						component: () =>
							import('../views/holder/pages/Stocks/StocksItem.vue'),
						props: true,
						meta: {
							title: 'Акции',
							holder: true,
						},
					},
					{
						path: '*',
						redirect: {
							name: 'ErrorPage',
						},
					},
				],
			},
			{
				path: 'partner-program',
				name: 'PartnerProgram',
				component: () => import('../views/holder/pages/Partners/Main.vue'),
				children: [
					{
						path: '',
						name: 'Partners',
						component: () =>
							import('../views/holder/pages/Partners/PartnerProgram.vue'),
						meta: {
							title: 'Партнеры и акции',
							holder: true,
						},
					},
					{
						path: 'partner/:id',
						name: 'PartnerProgram',
						component: () =>
							import('../views/holder/pages/Partners/Partner.vue'),
						props: true,

						meta: {
							title: '',
							holder: true,
						},
					},
					{
						path: '*',
						redirect: {
							name: 'ErrorPage',
						},
					},
				],
			},
			{
				path: '*',
				redirect: {
					name: 'ErrorPage',
				},
			},
		],
	},
	{
		path: '/partner',
		component: () => import('../views/partner/Main.vue'),
		children: [
			{
				path: '',
				name: 'PartnerHome',
				component: () => import('../views/partner/pages/Home.vue'),
				meta: {
					title: 'Единая социальная карта Свердловской области',
					partner: true,
				},
			},
			{
				path: 'advantages',
				name: 'Advantages',
				component: () => import('../views/partner/pages/Advantages.vue'),
				meta: {
					title: 'Преимущества',
					partner: true,
				},
			},
			{
				path: 'joining-procedure',
				name: 'JoiningProcedure',
				component: () => import('../views/partner/pages/JoiningProcedure.vue'),
				meta: {
					title: 'Порядок присоединения',
					partner: true,
				},
			},
			{
				path: 'materials',
				name: '',
				component: () => import('../views/partner/pages/Materials/Main.vue'),
				children: [
					{
						path: '',
						name: 'PartnerMaterials',
						component: () =>
							import('../views/partner/pages/Materials/Materials.vue'),
						meta: {
							title: 'Материалы',
							partner: true,
						},
					},
					{
						path: 'legal-framework',
						name: 'LegalFrameworkPartner',
						component: () =>
							import('../views/partner/pages/Materials/LegalFramework.vue'),
						meta: {
							title: 'Нормативно-правовая база проекта',
							partner: true,
						},
					},
					{
						path: 'guides-and-regulations',
						name: 'GuidesAndRegulationsPartner',
						component: () =>
							import(
								'../views/partner/pages/Materials/GuidesAndRegulations.vue'
							),
						meta: {
							title: 'Руководства и регламенты',
							partner: true,
						},
					},
					{
						path: 'transport',
						name: 'Transport',
						component: () =>
							import(
								'../views/partner/pages/Materials/Transport.vue'
							),
						meta: {
							title: 'Руководства и регламенты по предоставлению МСП на транспорте по ЕСК',
							partner: true,
						},
					},
					{
						path: '*',
						redirect: {
							name: 'ErrorPage',
						},
					},
				],
			},
			{
				path: 'news',
				component: () => import('../views/News/Main.vue'),
				children: [
					{
						path: '',
						name: 'NewsPartner',
						component: () => import('../views/News/News.vue'),
						meta: {
							title: 'Последние новости',
							partner: true,
						},
					},
					{
						path: ':id',
						name: 'NewsItemPartner',
						component: () => import('../views/News/NewsItem.vue'),
						props: true,
						meta: {
							title: 'Последние новости',
							partner: true,
						},
					},
					{
						path: '*',
						redirect: {
							name: 'ErrorPage',
						},
					},
				],
			},
			{
				path: 'contacts',
				name: 'ContactsPartner',
				component: () => import('../views/partner/pages/Contacts.vue'),
				meta: {
					title: 'Контактная информация',
					partner: true,
				},
			},
			{
				path: 'search',
				component: () => import('../views/search/Main.vue'),
				children: [
					{
						path: '',
						name: 'PartnerSearch',
						component: () => import('../views/search/Search.vue'),
						meta: {
							title: 'Поиск',
							partner: true,
						},
					},
					{
						path: '*',
						redirect: {
							name: 'ErrorPage',
						},
					},
				],
			},
			{
				path: '*',
				redirect: {
					name: 'ErrorPage',
				},
			},
		],
	},
	{
		path: '/operator',
		component: () => import('../views/operator/Main.vue'),
		children: [
			{
				path: '',
				redirect: {
					name: 'OperatorInformation',
				},
			},
			{
				path: 'operator-information',
				name: 'OperatorInformation',
				component: () =>
					import('../views/operator/pages/OperatorInformation.vue'),
				meta: {
					title: 'Информация об операторе',
					operator: true,
				},
			},
			{
				path: 'cooperation',
				name: 'Cooperation',
				component: () => import('../views/operator/pages/Cooperation.vue'),
				meta: {
					title: 'Сотрудничество с торгово-сервисными предприятиями',
					operator: true,
				},
			},
			{
				path: 'contacts',
				name: 'Contacts',
				component: () => import('../views/operator/pages/Contacts.vue'),
				meta: {
					title: 'Контактная информация',
					operator: true,
				},
			},
			{
				path: 'news',
				component: () => import('../views/News/Main.vue'),
				children: [
					{
						path: ':id',
						name: 'NewsItemOperator',
						component: () => import('../views/News/NewsItem.vue'),
						props: true,
						meta: {
							title: 'Последние новости',
							operator: true,
						},
					},
					{
						path: '',
						redirect: {
							name: 'OperatorInformation',
						},
					},
					{
						path: '*',
						redirect: {
							name: 'ErrorPage',
						},
					},
				],
			},
			{
				path: 'search',
				component: () => import('../views/search/Main.vue'),
				children: [
					{
						path: '',
						name: 'OperatorSearch',
						component: () => import('../views/search/Search.vue'),
						meta: {
							title: 'Поиск',
							operator: true,
						},
					},
					{
						path: '*',
						redirect: {
							name: 'ErrorPage',
						},
					},
				],
			},
			{
				path: '*',
				redirect: {
					name: 'ErrorPage',
				},
			},
		],
	},
	{
		path: '/lk',
		component: () => import('../views/lk/Main.vue'),
		children: [
			{
				path: '',
				redirect: 'Login',
			},
			{
				path: 'login',
				name: 'Login',
				component: () => import('../views/lk/pages/Login.vue'),
				meta: {
					title: 'Вход в личный кабинет',
					lk: true,
				},
			},
			{
				path: '*',
				redirect: {
					name: 'ErrorPage',
				},
			},
		],
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes,
	scrollBehavior(to, from, savedPosition) {
		if (savedPosition) {
			document.documentElement.scrollTop = savedPosition.y
			return savedPosition
		} else {
			return {
				x: 0,
				y: 0,
			}
		}
	},
})

router.beforeEach((to, from, next) => {
	const _to = to.fullPath.replace(/\/$/, "");
	//const _from = from.fullPath.replace(/\/$/, "");
	//const _current = window.location.pathname.replace(/\/$/, "");
	const flag = _to !== "" && to.fullPath && to.fullPath.match(/\/$/);
	if (flag) {
	  let _to2 = "";
	  for (let i = 1; i < to.fullPath.split(/\//).length - 1; i++) {
		_to2 += "/" + to.fullPath.split(/\//)[i];
	  }
	  next(_to2);
	} else next();
})


Vue.use(VueYandexMetrics, {
	id: 67492675,
	router: router,
	env: process.env.NODE_ENV,
})

export default router
