const state = {
	blockScroll: false,
}

const getters = {
	BLOCK_SCROLL: state => state.blockScroll,
}

const mutations = {
	SET_BLOCK_SCROLL: (state, payload) => {
		state.blockScroll = payload
	},
}

const actions = {
	SET_BLOCK_SCROLL: (context, payload) => {
		context.commit('SET_BLOCK_SCROLL', payload)
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
