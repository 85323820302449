import Axios from 'axios'

const state = {
	requestCardStatus: null,
}

const getters = {
	REQUEST_CARD_STATUS: state => state.requestCardStatus,
}

const mutations = {
	SET_REQUEST_CARD_STATUS: (state, payload) => {
		state.requestCardStatus = payload
	},
}

const actions = {
	PUSH_REQUEST_CARD: async (context, payload) => {
		await Axios({
			url: `${process.env.VUE_APP_API_ESK}/RegisterClient`,
			method: 'POST',
			headers: {
				Authorization: process.env.VUE_APP_AUTH_ESK,
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			data: JSON.stringify(payload),
		})
			.then(response => {
				context.commit('SET_REQUEST_CARD_STATUS', {
					...response.data,
					errors: false,
					awaitResponse: false,
				})
			})
			.catch(err => {
				context.commit('SET_REQUEST_CARD_STATUS', {
					...err.response.data[0],
					errors: true,
					awaitResponse: false,
				})
			})
	},

	SET_REQUEST_CARD_STATUS: (context, payload) => {
		context.commit('SET_REQUEST_CARD_STATUS', payload)
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
