const filter = {}

filter.sortingFilter = function(filters){
    const mainFilters = [
        "синара",
        "почта банк",
        "втб",
        "совкомбанк",
        "убрир",
        "газпромбанк",
        "гпб",
        "россельхозбанк",
        "сбербанк",

        "мфц", // МФЦ должен быть последним
    ]
    const partnerFilters = {"id":[],"name":[],"address":[],"phone":[],"webSiteURL":[], "isMain": true}
    const newFilters = filters.map((filter) => {
        let isMain = false
        let sortIndex = -1

        mainFilters.forEach((mFName, index) => {
            if (filter.name.toLowerCase().includes(mFName)) {
                isMain = true
                sortIndex = index
            }
        })

        if (!isMain) {
            partnerFilters.id.push(filter.id)
            partnerFilters.name.push(filter.name)
            partnerFilters.address.push(filter.address)
            partnerFilters.phone.push(filter.phone)
            partnerFilters.webSiteURL.push(filter.webSiteURL)
        }

        return { ...filter, isMain, sortIndex }
    }).sort((a, b) => a.sortIndex - b.sortIndex)
    const partnerFilter = {"id":0,"name":"Партнерские пункты","address":"","phone":"","webSiteURL":"", "isMain": true, "partners": partnerFilters}
    newFilters.push(partnerFilter)
    return newFilters
}


export default filter