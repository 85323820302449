<template>
	<div class="modal__content">
		<h2 class="modal__title">Заявка</h2>
		<perfect-scrollbar class="modal__scroll modal__scroll--indent-scroll">
			<ValidationObserver
				ref="observer"
				@submit.prevent="validateForm()"
				tag="form"
				class="form"
			>
				<fieldset class="form__fieldset">
					<div
						v-for="(item, index) in form"
						:key="index"
						:class="item.gridClass"
					>
						<label
							v-for="(element, key) in item.labels"
							:key="key"
							class="form__label"
							:class="item.labelClass"
						>
							<span
								v-if="element.selector === 'input'"
								class="visually-hidden"
								>{{ element.label }}</span
							>
							<validation-provider
								v-if="element.selector === 'input'"
								mode="passive"
								:rules="element.rules"
								v-slot="{ errors }"
								:name="element.code"
							>
								<input
									:type="element.type"
									:class="element.class"
									:placeholder="element.placeholder"
									v-mask="element.mask"
									v-model="request[element.code]"
									@blur="
										;(res_errors[element.code]
											? delete res_errors[element.code]
											: '') +
											(element.event ? events(element.event) : '') +
											(clickSend ? validateInput() : '')
									"
									@input="
										query(element.query) + (clickSend ? validateInput() : '')
									"
									:readonly="element.readonly"
								/>
								<span class="form__input-helper text-red">{{
									res_errors[element.code]
										? res_errors[element.code]
										: errors[0]
								}}</span>
							</validation-provider>
							<validation-provider
								v-if="element.selector === 'select'"
								mode="passive"
								:rules="element.rules"
								v-slot="{ errors }"
								:name="element.label"
							>
								<Select
									class="form__select"
									:options="element.options"
									v-model="selectedSex"
									:placeholder="element.placeholder"
									@input="clickSend ? validateInput() : ''"
									@blur="
										;(res_errors[element.code]
											? delete res_errors[element.code]
											: '') + +(clickSend ? validateInput() : '')
									"
								>
									<div slot="no-options">Ничего не найдено</div>
									<template #open-indicator="{ attributes }">
										<selectArrow v-bind="attributes"></selectArrow>
									</template>
									<template #search="{attributes, events}">
										<input
											class="vs__search"
											:required="!selectedSex"
											v-bind="attributes"
											v-on="events"
											readonly
										/>
									</template>
								</Select>
								<span class="form__input-helper text-red">{{
									res_errors[element.code]
										? res_errors[element.code]
										: errors[0]
								}}</span>
							</validation-provider>
							<transition name="filter__slide" v-if="element.show_transition">
								<perfect-scrollbar class="filter__box">
									<div
										class="filter__list"
										v-if="list(`${element.event.list}`)"
									>
										<button
											class="filter__item"
											type="button"
											v-for="(item2, ii) in suggestions[element.event.type]"
											:key="element.event.type + ii"
											@click="
												clickEvent(item2, element) +
													(clickSend ? validateInput() : '')
											"
										>
											{{ item2.value }}
										</button>
									</div>
								</perfect-scrollbar>
							</transition>
						</label>
					</div>
				</fieldset>

				<div class="flex flex--column flex--middle form__footer">
					<div style="position: relative;">
						<label class="checkbox">
							<input
								type="checkbox"
								class="visually-hidden"
								v-model="agreement"
								@click="
									agreement && agreementShow ? (agreementShow = false) : ''
								"
							/>

							<span class="checkbox__indicator"></span>
							<!--@click.native="closeModal"-->
							<span class="checkbox__text" style="margin-top:7px;">
								Я принимаю
								<router-link
									to="/holder/capabilities/soglasie"
									class="link"
									target="_blank"
									>соглашение</router-link
								>
								об использовании персональных данных
							</span>
						</label>
						<span v-show="agreementShow" class="form__input-helper text-red">{{
							agreementError
						}}</span>
					</div>
					<button
						class="button"
						type="submit"
						:disabled="response && response.awaitResponse"
						:class="{
							'animated-background': response && response.awaitResponse,
						}"
						@click="
							;(agreement && agreementShow ? (agreementShow = false) : '') +
								(clickSend = true)
						"
					>
						Отправить
					</button>
					<br/>
				</div>
			</ValidationObserver>
		</perfect-scrollbar>
	</div>
</template>

<script>
import Select from 'vue-select'
import 'vue-select/dist/vue-select.css'

import selectArrow from '@/assets/images/icons/icon_arrow-select.svg?inline'
import {
	ValidationObserver,
	ValidationProvider,
	extend,
	localize,
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
localize('ru')

localize({
	ru: {
		messages:{
			min:`Минимальная длина: 10 символов`,
			max:`Максимальная длина: 2000 символов`,
			alpharus: `Разрешены только русские буквы`,
			length: `Поле может состоять только из ${length} чисел`,
			required: 'Поле обязательно для заполнения',
			email: 'Значение не является электронной почтой',
		},
		fields: {
			firstName: {
				min: `Минимальная длина: 2 символа`,
				max: `Минимальная длина: 50 сиволов`,
			},
			lastName: {
				min: `Минимальная длина: 2 символа`,
				max: `Минимальная длина: 50 сиволов`,
			},
			middleName: {
				min: `Минимальная длина: 2 символа`,
				max: `Минимальная длина: 50 сиволов`,
			},
			agreement: {
				is: `Поле обязательно для заполнения`,
			},
			num: {
				length: `Поле может состоять только из 10 цифр`,
			},
			org: {
				required: 'Не выбрано место выдачи паспорта',
			},
			snils: {
				required: `СНИЛС пуст`,
				length: `СНИЛС может состоять только из 11 цифр`,
			},
			codeOrg: {
				length: 'Поле может состоять только из 6 цифр',
			},
			birthDate: {
				length: 'Введите дату рождения в формате дд.мм.гггг',
				required: 'Поле обязательно для заполнения',
			},
			date: {
				length: 'Ведите дату выдачи в формате дд.мм.гггг',
				required: 'Поле обязательно для заполнения',
			},
			mobilePhone: {
				length: 'Значение не является телефоном',
				required: 'Поле обязательно для заполнения',
			},
			address: {
				min: `Минимальная длина: 10 сиволов`,
				max: `Минимальная длина: 2000 сиволов`,
			},
			birthPlace: {
				min: `Минимальная длина: 10 сиволов`,
				max: `Минимальная длина: 2000 сиволов`,
			},
			addressReg: {
				min: `Минимальная длина: 10 сиволов`,
				max: `Минимальная длина: 2000 сиволов`,
			},
		},
	},
})
Object.keys(rules).forEach(rule => {
	extend(rule, rules[rule])
})

// with typescript
for (let [rule, validation] of Object.entries(rules)) {
	extend(rule, {
		...validation,
	})
}

extend('alpharus', {
	validate(value) {
		return /^[А-яЁё\s-]*$/i.test(value)
	},
	message: 'Разрешены только русские буквы',
})
extend('isSnils', {
	validate(value) {
		return validateSnils(value.replace(/[\s-]/g, ''))
	},
	message: 'Неправильное контрольное число',
})
extend('birthPlaceval', {
	validate(value) {
		return /^[А-яЁё\s0-9.,№-]*$/i.test(value)
	},
	message: 'Некорректное значение поля',
})

export default {
	name: 'ModalRequest',

	components: {
		Select,
		selectArrow,
		ValidationProvider,
		ValidationObserver,
	},

	data() {
		return {
			clickSend: false,
			agreementShow: false,
			agreementError: 'Поле обязательно для заполнения',
			request: {
				firstName: null,
				lastName: null,
				middleName: null,
				birthDate: null,
				birthPlace: null,
				sex: null,
				address: null,
				mobilePhone: null,
				email: null,
				num: null,
				date: null,
				codeOrg: null,
				org: null,
				addressReg: null,
				snils: null,
			},
			res_errors: {},
			selectedSex: null,
			agreement: false,
			queryNameList: false,
			querylastnameList: false,
			queryPatronymicList: false,
			queryPlaceOfBirthList: false,
			queryIssuedByList: false,
			queryRegistrationAddressList: false,
			queryAddressOfResidenceList: false,
			attributes: {
				ref: 'openIndicator',
				role: 'presentation',
				class: 'vs__open-indicator',
			},
		}
	},

	methods: {
		validateInput() {
			setTimeout(() => {
				this.$refs.observer.validate()
			}, 150)
		},
		clickEvent(item, elm) {
			if (elm.code == 'org') {
				this.setPassportOrg(item)
			} else {
				if (elm.code == 'middleName') {
					this.set(elm.code, item.data.patronymic)
				} else {
					this.set(elm.code, item.value)
				}
				this[elm.event.list] = false
			}
		},
		closeModal() {
			this.$store.dispatch('GET_MODAL')
		},
		async validateForm() {
			const isValid = await this.$refs.observer.validate()
			if (!isValid) {
				return false
			}
			if (!this.agreement) {
				this.agreementShow = true
				return false
			}
            this.pushFeedback(null)
		},
		pushFeedback(token) {
			let data = {
				firstName: this.request.firstName,
				lastName: this.request.lastName,
				middleName: this.request.middleName,
				birthDate: this.request.birthDate,
				birthPlace: this.request.birthPlace,
				sex: this.selectedSex.data,
				contacts: {
					address: this.request.address,
					mobilePhone: this.request.mobilePhone
						.replace(/[\s-()]/g, '')
						.substr(2),
					email: this.request.email != '' ? this.request.email : null,
				},
				docs: {
					passport: {
						num: this.request.num,
						date: this.request.date,
						codeOrg: this.request.codeOrg.replace(/[-]/g, ''),
						org: this.request.org,
						addressReg: this.request.addressReg,
					},
					snils: this.request.snils.replace(/[\s-]/g, ''),
				},
				token: token,
			}

			this.$store.dispatch('PUSH_REQUEST_CARD', data)
			this.$store.dispatch('SET_REQUEST_CARD_STATUS', {
				awaitResponse: true,
				errors: null,
			})
		},

		selectSex(value) {
			this.selectedSex = value
		},

		events(data) {
			if (data === null) return false

			setTimeout(() => {
				this[data.list] = false
				if (this[data.value] && this[data.value].length > 0) {
					this.$v[data.value].$touch()
				}

				if (data.list != 'queryIssuedByList') {
					this.$store.commit('SET_DADATA', { type: data.type, data: null })
				}
			}, 500)
		},
		query(data) {
			if (data == null) return false
			const options = {
				count: 30,
				from_bound: {},
				query:
					data.code == 'middleName'
						? `${this.request['lastName'] || ''} ${this.request['firstName'] ||
								''} ${this.request[data.code] || ''}`
						: this.request[data.code],
				to_bound: {},
			}

			if (options.query.length > 0) {
				this[data.list] = true
				this.$store.dispatch('GET_DADATA', {
					type: data.type,
					data: options,
					query: data.query,
				})
			} else {
				this[data.list] = false
				this.$store.commit('SET_DADATA', { type: data.type, data: null })
			}
		},

		set(code, value) {
			this.request[code] = value
		},
		setPassportOrg(data) {
			this.request['codeOrg'] = data.data.code
			setTimeout(() => {
				this.request['org'] = data.value
				this.queryIssuedByList = false
			}, 100)
		},

		switchModal() {
			this.$store.dispatch('GET_MODAL', { type: 4 })
		},
		list(list) {
			return this[list]
		},
	},

	computed: {
		form() {
			return [
				{
					gridClass: 'flex flex--between',
					labels: [
						{
							code: 'firstName',
							label: 'Имя',
							selector: 'input',
							type: 'text',
							class: 'form__input',
							placeholder: 'Имя*',
							rules: { required: true, min: 2, max: 50, alpharus: true },
							maxlength: 50,
							minlength: 2,
							event: {
								value: 'firstName',
								type: 'name',
								list: 'queryNameList',
							},
							query: {
								code: 'firstName',
								list: 'queryNameList',
								type: 'name',
								query: 'fio',
							},
							show_transition: true,
						},
						{
							code: 'lastName',
							label: 'Фамилия',
							selector: 'input',
							type: 'text',
							class: 'form__input',
							placeholder: 'Фамилия*',
							maxlength: 50,
							minlength: 1,
							rules: { required: true, min: 2, max: 50, alpharus: true },
							event: {
								value: 'lastName',
								type: 'lastname',
								list: 'querylastnameList',
							},
							query: {
								code: 'lastName',
								list: 'querylastnameList',
								type: 'lastname',
								query: 'fio',
							},
							show_transition: true,
						},
					],
				},
				{
					gridClass: 'flex flex--between',
					labels: [
						{
							code: 'middleName',
							label: 'Отчество',
							selector: 'input',
							type: 'text',
							class: 'form__input',
							placeholder: 'Отчество',
							maxlength: 50,
							minlength: 2,
							rules: { min: 2, max: 50, alpharus: true },
							event: {
								value: 'patronymic',
								type: 'patronymic',
								list: 'queryPatronymicList',
							},
							query: {
								code: 'middleName',
								list: 'queryPatronymicList',
								type: 'patronymic',
								query: 'fio',
							},
							show_transition: true,
						},
						{
							code: 'sex',
							selector: 'select',
							if: 'sex',
							options: [
								{
									label: 'Мужской',
									data: 0,
								},
								{
									label: 'Женский',
									data: 1,
								},
							],
							class: 'form__select',
							placeholder: 'Пол',
							rules: { required: true },
							regex: '^[0-1]{1}$',
						},
					],
				},
				{
					gridClass: 'flex flex--between',
					labels: [
						{
							code: 'birthDate',
							label: 'Дата рождения',
							selector: 'input',
							type: 'text',
							class: 'form__input',
							placeholder: 'Дата рождения*',
							rules: { required: true, length: 10 },
							mask: timeMask(this.request['birthDate']),
						},
						{
							code: 'num',
							label: 'Серия и номер паспорта',
							selector: 'input',
							type: 'text',
							class: 'form__input',
							placeholder: 'Серия и номер паспорта*',
							rules: { required: true, length: 11 },
							mask: '#### ######',
						},
					],
				},
				{
					gridClass: 'flex flex--between',
					labels: [
						{
							code: 'date',
							label: 'Дата выдачи',
							selector: 'input',
							type: 'text',
							class: 'form__input',
							placeholder: 'Дата выдачи*',
							rules: { required: true, length: 10 },
							mask: timeMask(this.request['date']),
						},
						{
							code: 'codeOrg',
							label: 'Код подразделения',
							selector: 'input',
							type: 'text',
							class: 'form__input',
							placeholder: 'Код подразделения*',
							rules: { required: true, length: 7 },
							mask: '###-###',
							event: null,
							query: {
								code: 'codeOrg',
								list: 'queryIssuedByList',
								type: 'fms',
								query: 'fms_unit',
							},
							show_transition: false,
						},
					],
				},
				{
					gridClass: 'flex',
					labelClass: 'form__label--full',
					labels: [
						{
							code: 'org',
							label: 'Кем выдан',
							selector: 'input',
							type: 'text',
							class: 'form__input',
							placeholder: 'Кем выдан*',
							rules: {
								required: true,
								min: 8,
								max: 2000,
							},
							readonly: true,
							maxlength: 2000,
							minlength: 8,
							regex: '^[А-яЁё\\s0-9.,\\/№\\-]{8,2000}$',
							event: {
								value: 'org',
								type: 'fms',
								list: 'queryIssuedByList',
							},
							query: {
								code: 'org',
								list: 'queryIssuedByList',
								type: 'fms',
								query: 'fms_unit',
							},
							show_transition: true,
						},
					],
				},
				{
					gridClass: 'flex',
					labelClass: 'form__label--full',
					labels: [
						{
							code: 'snils',
							label: 'СНИЛС',
							selector: 'input',
							type: 'text',
							class: 'form__input',
							placeholder: 'СНИЛС*',
							rules: { required: true, length: 14, isSnils: true },
							mask: '###-###-### ##',
						},
					],
				},
				{
					gridClass: 'flex',
					labelClass: 'form__label--full',
					labels: [
						{
							code: 'address',
							label: 'Адрес проживания (фактический)',
							selector: 'input',
							type: 'text',
							class: 'form__input',
							maxlength: 2000,
							minlength: 10,
							placeholder: 'Адрес проживания (фактический)*',
							rules: { required: true, min: 10, max: 2000 },
							regex: '^[А-яЁё\\s0-9.,\\/№\\-]{10,2000}$',
							event: {
								value: 'address',
								type: 'address',
								list: 'queryAddressOfResidenceList',
							},
							query: {
								code: 'address',
								list: 'queryAddressOfResidenceList',
								type: 'address',
								query: 'address',
							},
							show_transition: true,
						},
					],
				},
				{
					gridClass: 'flex',
					labelClass: 'form__label--full',
					labels: [
						{
							code: 'addressReg',
							label: 'Адрес регистрации',
							selector: 'input',
							type: 'text',
							class: 'form__input',
							placeholder: 'Адрес регистрации*',
							rules: { required: true, min: 10, max: 2000 },
							maxlength: 2000,
							minlength: 10,
							regex: '^[А-яЁё\\s0-9.,\\/№\\-]{10,2000}$',
							event: {
								value: 'addressReg',
								type: 'address',
								list: 'queryRegistrationAddressList',
							},
							query: {
								code: 'addressReg',
								list: 'queryRegistrationAddressList',
								type: 'address',
								query: 'address',
							},
							show_transition: true,
						},
					],
				},
				{
					gridClass: 'flex',
					labelClass: 'form__label--full',
					labels: [
						{
							code: 'birthPlace',
							label: 'Место рождения',
							selector: 'input',
							type: 'text',
							class: 'form__input',
							placeholder: 'Место рождения*',
							rules: { required: true, min: 3, max: 2000, birthPlaceval: true },
							maxlength: 2000,
							minlength: 3,
							regex: '^[А-яЁё\\s0-9.,\\/№\\-]{3,2000}$',
							event: {
								value: 'birthPlace',
								type: 'address',
								list: 'queryPlaceOfBirthList',
							},
							query: {
								code: 'birthPlace',
								list: 'queryPlaceOfBirthList',
								type: 'address',
								query: 'address',
							},
							show_transition: true,
						},
					],
				},
				{
					gridClass: 'flex flex--between',
					labels: [
						{
							code: 'mobilePhone',
							label: 'Мобильный телефон',
							selector: 'input',
							type: 'text',
							class: 'form__input',
							placeholder: 'Мобильный телефон*',
							rules: { required: true, length: 18 },
							mask: '8 (###) ### ## ##',
						},
						{
							code: 'email',
							label: 'Электронная почта',
							selector: 'input',
							type: 'text',
							rules: { required: true, email: true },
							class: 'form__input',
							placeholder: 'Электронная почта*',
						},
					],
				},
			]
		},
		response() {
			const response = this.$store.getters.REQUEST_CARD_STATUS
			const setting = {
				theme: 'toasted-primary',
				position: 'top-right',
				duration: 6000,
				keepOnHover: true,
			}
			let message = ''

			if (response && response.errors == false) {
				this.switchModal()
			} else if (response && response.errors == true && response.message) {
				message = response.message.replace('firstName', 'Имя')
				message = message.replace('lastname', 'Фамилия')
				message = message.replace('middleName', 'Отчество')
				message = message.replace('birthDate', 'Дата рождения')
				message = message.replace('birthPlace', 'Место рождения')
				message = message.replace('sex', 'Пол')
				message = message.replace('contacts.address', 'Место проживания')
				message = message.replace('contacts.mobilePhone', 'Телефон')
				message = message.replace('contacts.email', 'Email')
				message = message.replace('docs.passport.num', 'Серия и номер паспорта')
				message = message.replace('docs.passport.date', 'Дата выдачи паспорта')
				message = message.replace('docs.passport.codeOrg', 'Код подразделения')
				message = message.replace('docs.passport.org', 'Место выдачи паспорта')
				message = message.replace(
					'docs.passport.addressReg',
					'Адрес регистрации'
				)
				message = message.replace('docs.snils', 'Снилс')
				// eslint-disable-next-line vue/no-side-effects-in-computed-properties
				this.res_errors[response.code.split('.').pop()] = message
				if (response.code != '') {
					this.$toasted.show(message, {
						...setting,
						type: 'error',
					})
				}
			}

			return response
		},

		suggestions() {
			let daData = this.$store.getters.DADATA

			if (daData && daData.name) {
				daData.name = daData.name.filter(item => item.data.name)
			}
			if (daData && daData.lastname) {
				daData.lastname = daData.lastname.filter(item => item.data.surname)
			}

			if (daData && daData.patronymic) {
				daData.patronymic = daData.patronymic.filter(
					item => item.data.patronymic
				)
			}

			return daData
		},
	},

	beforeDestroy() {
		this.$store.dispatch('SET_REQUEST_CARD_STATUS', null)
	},
}

export function validateSnils(snils) {
	let result = false
	var sum = 0
	for (var i = 0; i < 9; i++) {
		sum += parseInt(snils[i]) * (9 - i)
	}
	var checkDigit = 0
	if (sum < 100) {
		checkDigit = sum
	} else if (sum > 101) {
		checkDigit = parseInt(sum % 101)
		if (checkDigit === 100) {
			checkDigit = 0
		}
	}
	if (checkDigit === parseInt(snils.slice(-2))) {
		result = true
	} else {
		return false
	}
	return result
}
export function timeMask(value) {
	if (value == null) {
		return '##.##.####'
	}
	const thisYear = new Date().getFullYear().toString()
	const days = [/[0-3]/, value.charAt(0) === '3' ? /[0-1]/ : /[0-9]/]
	const month = [/[0-1]/, value.charAt(3) === '1' ? /[0-2]/ : /[0-9]/]
	const year = [
		/[1-2]/,
		value.charAt(6) === '1' ? /[9]/ : /[0]/,
		value.charAt(6) === '1' ? /[0-9]/ : /[0-2]/,
		value.charAt(8) === '2'
			? thisYear.charAt(3) === '0'
				? /[0]/
				: thisYear.charAt(3) === '1'
				? /[1]/
				: thisYear.charAt(3) === '2'
				? /[2]/
				: thisYear.charAt(3) === '3'
				? /[3]/
				: thisYear.charAt(3) === '4'
				? /[4]/
				: thisYear.charAt(3) === '5'
				? /[5]/
				: thisYear.charAt(3) === '6'
				? /[6]/
				: thisYear.charAt(3) === '7'
				? /[7]/
				: thisYear.charAt(3) === '8'
				? /[8]/
				: /[9]/
			: /[0-9]/,
	]

	return value.length > 5
		? [...days, '.', ...month, '.', ...year]
		: value.length > 2
		? [...days, '.', ...month]
		: [...days]
}
</script>
