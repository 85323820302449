import Axios from 'axios'

const state = {
	materials: {
		holder: {
			basic: null,
			guides: null,
			normative: null,
			transport: null,
		},
		partner: {
			basic: null,
			guides: null,
			normative: null,
			transport: null,
		},
		operator: {
			basic: null,
			guides: null,
			normative: null,
			transport: null,
		},
	},
	material: null,
}

const getters = {
	MATERIALS: state => state.materials,

	MATERIAL: state => state.material,
}

const mutations = {
	SET_MATERIALS: (state, payload) => {
		const category =
			payload.category == 1
				? 'holder'
				: payload.category == 2
				? 'partner'
				: 'operator'

		state.materials[category][payload.type] = payload.data
	},

	SET_MATERIAL: (state, payload) => {
		state.material = payload
		const linkUrl = document.createElement('a')

		linkUrl.download = payload.url.substring(
			payload.url.lastIndexOf('/') + 1,
			payload.url.length
		)
		linkUrl.href = payload.url
		document.body.appendChild(linkUrl)
		linkUrl.click()
		document.body.removeChild(linkUrl)
	},
}

const actions = {
	GET_MATERIALS: async (context, payload) => {
		await Axios({
			url: `${process.env.VUE_APP_API_VERSION}/material?category=${payload.category}&type=${payload.type}`,
			method: 'GET',
		})
			.then(response => {
				context.commit('SET_MATERIALS', {
					category: payload.category,
					type: payload.type,
					data: response.data,
				})
			})
			.catch(err => {
				console.log(err)
			})
	},

	GET_MATERIAL: async (context, payload) => {
		await Axios({
			url: `${process.env.VUE_APP_API_VERSION}/material/${payload}`,
			method: 'GET',
		})
			.then(response => {
				context.commit('SET_MATERIAL', response.data)
			})
			.catch(err => {
				console.log(err)
			})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
