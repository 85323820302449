import Axios from 'axios'

const state = {
	feedback: null,
	feedbackNeedAnswer: null,
	ListFeedbackThemes: null,
	feedbackStatus: null,
	feedbackStatusEsk: null,
}

const getters = {
	FEEDBACK: state => state.feedback,

	FEEDBACK_NEED_ANSWER: state => state.feedbackNeedAnswer,

	LIST_FEEDBACK_THEMES: state => state.ListFeedbackThemes,

	FEEDBACK_STATUS: state => state.feedbackStatus,

	FEEDBACK_STATUS_ESK: state => state.feedbackStatusEsk,
}

const mutations = {
	SET_FEEDBACK: (state, payload) => {
		state.feedback = payload
	},

	SET_FEEDBACK_NEED_ANSWER: (state, payload) => {
		state.feedbackNeedAnswer = payload
	},

	SET_LIST_FEEDBACK_THEMES: (state, payload) => {
		state.ListFeedbackThemes = payload
	},

	SET_FEEDBACK_STATUS: (state, payload) => {
		state.feedbackStatus = payload
	},

	SET_FEEDBACK_STATUS_ESK: (state, payload) => {
		state.feedbackStatusEsk = payload
	},
}

const actions = {
	GET_FEEDBACK_SUBJECTS: async context => {
		await Axios({
			url: `${process.env.VUE_APP_API_VERSION}/feedback/subjects`,
			method: 'GET',
		})
			.then(response => {
				context.commit('SET_FEEDBACK', response.data)
			})
			.catch(err => {
				console.log(err)
			})
	},

	GET_LIST_FEEDBACK_THEMES: async context => {
		await Axios({
			url: `${process.env.VUE_APP_API_ESK}/ListFeedbackThemes`,
			method: 'GET',
			headers: {
				Authorization: process.env.VUE_APP_AUTH_ESK,
			},
		})
			.then(response => {
				context.commit('SET_LIST_FEEDBACK_THEMES', response.data)
			})
			.catch(err => {
				console.log(err)
			})
	},

	PUSH_FEEDBACK: async (context, payload) => {
		await Axios({
			url: `${process.env.VUE_APP_API_VERSION}/feedback`,
			method: 'POST',
			data: payload,
		})
			.then(response => {
				context.commit('SET_FEEDBACK_STATUS', {
					...response.data,
					awaitResponse: false,
				})
			})
			.catch(err => {
				console.log(err)
				context.commit('SET_FEEDBACK_STATUS', {
					errors: true,
					awaitResponse: false,
				})
			})
	},

	PUSH_FEEDBACK_ESK: async (context, payload) => {
		await Axios({
			url: `${process.env.VUE_APP_API_ESK}/Feedback`,
			method: 'POST',
			headers: {
				Authorization: process.env.VUE_APP_AUTH_ESK,
				'Content-Type': 'multipart/form-data',
			},
			data: payload,
		})
			.then(response => {
				context.commit('SET_FEEDBACK_STATUS_ESK', {
					...response.data,
					success: true,
					awaitResponse: false,
				})
			})
			.catch(err => {
				console.log(err)
				context.commit('SET_FEEDBACK_STATUS_ESK', {
					...err.response.data,
					errors: true,
					awaitResponse: false,
				})
			})
	},

	SET_FEEDBACK_STATUS: (context, payload) => {
		context.commit('SET_FEEDBACK_STATUS', payload)
	},

	SET_FEEDBACK_STATUS_ESK: (context, payload) => {
		context.commit('SET_FEEDBACK_STATUS_ESK', payload)
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
