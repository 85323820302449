import Axios from 'axios'
//import CyrillicToTranslit from 'cyrillic-to-translit-js';
//const cyrillicToTranslit = new CyrillicToTranslit();
const state = {
	cities: null,
	geo: null,
	pickedCity: localStorage.getItem('pickedCity')
		? JSON.parse(localStorage.getItem('pickedCity'))
		: null,
}

const getters = {
	GEO: state => state.geo,
	IP: state => state.ip,
	CITIES: state => state.cities,
	PICKEDCITY: state => state.pickedCity,
}

const mutations = {
	SET_CITIES: (state, payload) => {
		state.cities = payload
	},
	SET_IP: (state, payload) => {
		state.ip = payload
	},
	SET_GEO: (state, payload) => {
		state.geo = payload
	},
	SET_PICKEDCITY: (state, payload) => {
		const data = payload
		state.pickedCity = data
	},
}

const actions = {
	GET_IP: async context => {
		await Axios({ url: 'https://ipinfo.io/json', method: 'GET' })
			.then(response => {
				context.commit('SET_IP', response.data.ip)
			})
			.catch(err => {
				console.log(err)
			})
	},
	GET_CITIES: async context => {
		await Axios({
			url: `${process.env.VUE_APP_API_ESK}/ListLocalities`,
			method: 'GET',
			headers: {
				Authorization: process.env.VUE_APP_AUTH_ESK,
			},
		})
			.then(response => {
			context.commit('SET_CITIES', response.data)
				Axios({ url: 'https://ipinfo.io/json', method: 'GET' })
					.then(response => {
						//
						//const u = `${process.env.VUE_APP_API_ESK}/v1/geo?ip=${response.data.ip}`
						const u = `${process.env.VUE_APP_API_VERSION}/geo?ip=${response.data.ip}`
						Axios({ url: u, method: 'GET' })
							.then(response => {
								let isCityDetect = false
								if (!state.pickedCity) {
									state.cities.forEach(item => {
										if (item.name == response.data.city.name_ru) {
											context.commit('SET_PICKEDCITY', item)
											isCityDetect = true
										}
									})
									//Если город не выбран и город не найден
									if (!isCityDetect) {
										state.cities.forEach(item => {
											if (item.name == 'Екатеринбург') {
												context.commit('SET_PICKEDCITY', item)
											}
										})
									}
								} else {
									/*
									//Если город выбран
									state.cities.forEach(item => {
										if (item.name == 'Екатеринбург') {
											context.commit('SET_PICKEDCITY', item)
										}
									})
									*/
								}
							})
							.catch(err => {
								console.log(
									`Ошибка получения города по IP по адресу ${u}. Город не определен, города згружены по умолчанию`,
									err
								)
								//Если произошла ошибка выбора города
								state.cities.forEach(item => {
									if (item.name == 'Екатеринбург') {
										context.commit('SET_PICKEDCITY', item)
									}
								})
							})
					})
					.catch(err => {
						//Если произошла ошибка выбора города
						if(!state.pickedCity) {
							state.cities.forEach(item => {
								if (item.name == 'Екатеринбург') {
									context.commit('SET_PICKEDCITY', item)
								}
							})
						}
						console.log('Ошибка получения IP или заблокирован браузером', err)
					})
			})
			.catch(err => {
				console.log('Ошибка получения списка городов', err)
			})
	},
	GET_GEO: async context => {
		await Axios({ url: `${process.env.VUE_APP_API_ESK}/geo?`, method: 'GET' })
			.then(response => {
				context.commit('SET_GEO', response.data)
			})
			.catch(err => {
				console.log(err)
			})
	},
	SET_PICKEDCITY: (context, payload) => {
		context.commit('SET_PICKEDCITY', payload)
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
