<template>
	<transition name="modal-slide">
		<section class="modal" v-if="showModal.active">
			<button class="modal__close-bg" @click="closeModal">
				<span class="visually-hidden">Закрыть модальное окно</span>
			</button>
			<div class="modal__box">
				<ModalCity
					:data="getCities"
					v-if="showModal.type == 1 && getCities"
				></ModalCity>
				<ModalFeedback v-if="showModal.type == 2"></ModalFeedback>
				<ModalRequest v-if="showModal.type == 3"></ModalRequest>
				<ModalSuccessRequest v-if="showModal.type == 4"></ModalSuccessRequest>
				<ModalSuccessFeedback v-if="showModal.type == 5"></ModalSuccessFeedback>

				<button class="modal__close map__button-close" @click="closeModal">
					<!--Закрыть-->
				</button>
			</div>
		</section>
	</transition>
</template>

<style>
	.modal__content.modal_small__content {
		height: unset;
	}
</style>

<script>
import ModalCity from '@/components/ModalCity.vue'
import ModalFeedback from '@/components/ModalFeedback.vue'
import ModalRequest from '@/components/ModalRequest.vue'
import ModalSuccessRequest from '@/components/ModalSuccessRequest.vue'
import ModalSuccessFeedback from '@/components/ModalSuccessFeedback.vue'

export default {
	name: 'Modal',

	components: {
		ModalCity,
		ModalFeedback,
		ModalRequest,
		ModalSuccessRequest,
		ModalSuccessFeedback,
	},

	methods: {
		closeModal() {
			this.$store.dispatch('GET_MODAL')
		},
	},

	computed: {
		modal() {
			const state = this.$store.getters.MODAL
			let name = null
			let data = null

			switch (state.type) {
				case 1:
					name = 'ModalCity'
					data = this.getCities
					break
				case 2:
					name = 'ModalFeedback'
					break
				case 3:
					name = 'ModalRequest'
					break
				case 4:
					name = 'ModalSuccess'
					break
			}

			return {
				name,
				data,
			}
		},

		showModal() {
			const state = this.$store.getters.MODAL

			let vh = window.innerHeight * 0.01

			document.documentElement.style.setProperty('--vh', `${vh}px`)

			window.addEventListener('resize', () => {
				let vh = window.innerHeight * 0.01
				document.documentElement.style.setProperty('--vh', `${vh}px`)
			})

			if (state.active) {
				this.$store.dispatch('SET_BLOCK_SCROLL', true)
			} else {
				this.$store.dispatch('SET_BLOCK_SCROLL', false)
			}

			return state
		},

		getCities() {
			return this.$store.getters.CITIES
		},
	},
	created() {
		const self = this
		self.$root.$on('modal', function (action) {
			if(action == 'close') self.closeModal()
		});
	},
	mounted() {
		this.$store.dispatch('GET_CITIES')
	},
}
</script>
