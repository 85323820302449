import Axios from 'axios'

const state = {
	listLoyaltyPrograms: null,
}

const getters = {
	LIST_LOYALTY_PROGRAMS: state => state.listLoyaltyPrograms,
}

const mutations = {
	SET_LIST_LOYALTY_PROGRAMS: (state, payload) => {
		state.listLoyaltyPrograms = payload
	},
}

const actions = {
	GET_LIST_LOYALTY_PROGRAMS: async context => {
		await Axios({
			url: `${process.env.VUE_APP_API_ESK}/ListLoyaltyPrograms`,
			method: 'GET',
			headers: {
				Authorization: process.env.VUE_APP_AUTH_ESK,
			},
		})
			.then(response => {
				context.commit('SET_LIST_LOYALTY_PROGRAMS', response.data)
			})
			.catch(err => {
				console.log(err)
			})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
