<template>
	<li
		@mouseleave="mouseOut"
		v-click-outside="mouseOut"
		class="nav__item nav__item-top"
		v-if="data"
			>
		<span @mouseover="mouseOver($event, data)" style="display:block;">
			<router-link :to="data.item" class="nav__link" active-class="active">
				{{ data.title }}
			</router-link>
		</span>
		<div
			v-if="data.hasOwnProperty('menu') && data.menu.length > 0"
			@mouseleave="mouseOut"
			style="display:block;height:100%;"
		>
			<ul :ref="'sub__menu_' + data.path" class="sub__menu">
				<li v-for="(item, index) in data.menu" :key="index">
					<router-link :to="item.item" class="nav__link" active-class="active">
						{{ item.title }}
					</router-link>
				</li>
			</ul>
		</div>
	</li>
</template>
<script>
export default {
	name: 'HeaderItem',
	data() {
		return {
		showMenu: false,
		}
	},
	props: {
		data: {
			type: Object,
			required: true,
		},
	},
	methods: {
		mouseOver: function(e, data) {
			let subMenu = document.getElementsByClassName('sub__menu')
			if(subMenu.length > 0){
				subMenu.forEach(function(ul) {
					ul.style.display = 'none'
				})
				let key = 'sub__menu_' + data.path
				if (this.$refs[key] !== undefined) {
					this.$refs[key].style.display = 'block'
				}
			}
		},
		mouseOut: function() {
			let subMenu = document.getElementsByClassName('sub__menu')
			if(subMenu.length > 0){
				subMenu.forEach(function(ul) {
				ul.style.display = 'none'
			})
		}
	},
	},
	created() {
		let self = this
		try {
			window.addEventListener('scroll', function() {
				self.mouseOut()
			})
		} catch (e) {
			console.log(e)
		}
	},
}
</script>
