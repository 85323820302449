<template>
	<div class="visually-impaired">
		<button class="visually-impaired__button-close" @click="hideNav">
			<span class="visually-hidden">
				Скрыть панель с настройками
			</span>
		</button>
		<div class="container">
			<form class="visually-impaired__list">
				<fieldset class="visually-impaired__item">
					<legend class="visually-impaired__title">
						Размер шрифта
					</legend>
					<div class="visually-impaired__box flex">
						<label
							class="visually-impaired__label visually-impaired__label--fixed-size"
						>
							<input
								type="radio"
								name="font-size"
								value="small"
								class="visually-hidden"
								v-model="fontSize"
								@change="setFontSize"
							/>
							<span
								class="visually-impaired__text visually-impaired__text--small"
								data-letter="А"
							></span>
							<span class="visually-hidden">
								Маленький размер шрифта
							</span>
						</label>
						<label
							class="visually-impaired__label visually-impaired__label--fixed-size"
						>
							<input
								type="radio"
								name="font-size"
								value="medium"
								class="visually-hidden"
								v-model="fontSize"
								@change="setFontSize"
							/>
							<span
								class="visually-impaired__text visually-impaired__text--medium"
								data-letter="А"
							></span>
							<span class="visually-hidden">
								Средний размер шрифта
							</span>
						</label>
						<label
							class="visually-impaired__label visually-impaired__label--fixed-size"
						>
							<input
								type="radio"
								name="font-size"
								value="large"
								class="visually-hidden"
								v-model="fontSize"
								@change="setFontSize"
							/>
							<span
								class="visually-impaired__text visually-impaired__text--large"
								data-letter="А"
							></span>
							<span class="visually-hidden">
								Большой размер шрифта
							</span>
						</label>
					</div>
				</fieldset>
				<fieldset class="visually-impaired__item">
					<legend class="visually-impaired__title">
						Цвет сайта
					</legend>
					<div class="visually-impaired__box flex">
						<label class="visually-impaired__label">
							<input
								type="radio"
								class="visually-hidden"
								name="color"
								value="normal"
								v-model="color"
								@change="setColor"
							/>
							<span class="visually-impaired__button" data-letter="А"></span>
							<span class="visually-hidden">
								Чёрнобелый цвет
							</span>
						</label>
						<label class="visually-impaired__label">
							<input
								type="radio"
								class="visually-hidden"
								name="color"
								value="invert"
								v-model="color"
								@change="setColor"
							/>
							<span
								class="visually-impaired__button visually-impaired__button--color-invert"
								data-letter="А"
							></span>
							<span class="visually-hidden">
								Инвертированные цвета
							</span>
						</label>
						<label class="visually-impaired__label">
							<input
								type="radio"
								class="visually-hidden"
								name="color"
								value="contrast"
								v-model="color"
								@change="setColor"
							/>
							<span
								class="visually-impaired__button visually-impaired__button--color-blue"
								data-letter="А"
							></span>
							<span class="visually-hidden">
								Высокая контрастность
							</span>
						</label>
					</div>
				</fieldset>
				<fieldset class="visually-impaired__item">
					<legend class="visually-impaired__title">
						Изображения
					</legend>
					<div class="toggle">
						<label class="toggle__cover">
							<div class="toggle__button">
								<input
									type="checkbox"
									class="toggle__checkbox"
									v-model="images"
									@change="setImages"
								/>
								<div class="toggle__knobs" data-disable="Выкл">
									<span>Вкл</span>
								</div>
								<div class="toggle__layer"></div>
							</div>
						</label>
					</div>
				</fieldset>
				<fieldset class="visually-impaired__item">
					<legend class="visually-impaired__title">
						Интервал
					</legend>
					<div class="visually-impaired__box flex">
						<label class="visually-impaired__label">
							<input
								type="radio"
								class="visually-hidden"
								name="letter-spacing"
								value="normal"
								v-model="letterSpacing"
								@change="setLetterSpacing"
							/>
							<span class="visually-impaired__text">
								Нормальный
							</span>
						</label>
						<label class="visually-impaired__label">
							<input
								type="radio"
								class="visually-hidden"
								name="letter-spacing"
								value="medium"
								v-model="letterSpacing"
								@change="setLetterSpacing"
							/>
							<span class="visually-impaired__text">
								Увеличенный
							</span>
						</label>
						<label class="visually-impaired__label">
							<input
								type="radio"
								class="visually-hidden"
								name="letter-spacing"
								value="large"
								v-model="letterSpacing"
								@change="setLetterSpacing"
							/>
							<span class="visually-impaired__text">
								Большой
							</span>
						</label>
					</div>
				</fieldset>
				<fieldset class="visually-impaired__item">
					<legend class="visually-impaired__title">
						Шрифт
					</legend>
					<div class="visually-impaired__box flex">
						<label class="visually-impaired__label">
							<input
								type="radio"
								class="visually-hidden"
								name="font-style"
								value="sans-serif"
								v-model="fontStyle"
								@change="setFontStyle"
							/>
							<span class="visually-impaired__text">
								Без засечек
							</span>
						</label>
						<label class="visually-impaired__label">
							<input
								type="radio"
								class="visually-hidden"
								name="font-style"
								value="serif"
								v-model="fontStyle"
								@change="setFontStyle"
							/>
							<span class="visually-impaired__text">
								С засечками
							</span>
						</label>
					</div>
				</fieldset>
				<button
					type="button"
					class="link visually-impaired__back-to-menu"
					@click="openMenu"
				>
					Вернуться в меню
				</button>
			</form>
		</div>
	</div>
</template>

<script>
export default {
	name: 'HeaderVisuallyImpairedBlock',

	data() {
		return {
			fontSize: null,
			color: null,
			images: null,
			letterSpacing: null,
			fontStyle: null,
		}
	},

	methods: {
		setFontSize() {
			this.$store.dispatch('SET_FONT_SIZE', this.fontSize)
		},

		setColor() {
			this.$store.dispatch('SET_COLOR', this.color)
		},

		setImages() {
			this.$store.dispatch('SET_IMAGES', Number(this.images))
		},

		setLetterSpacing() {
			this.$store.dispatch('SET_LETTER_SPACING', this.letterSpacing)
		},

		setFontStyle() {
			this.$store.dispatch('SET_FONT_STYLE', this.fontStyle)
		},

		hideNav() {
			this.$root.$emit('hideVisuallyImpaired')
		},

		openMenu() {
			this.$root.$emit('openMenu')
		},
	},

	mounted() {
		this.fontSize = this.$store.getters.FONT_SIZE
		this.color = this.$store.getters.COLOR
		this.images = this.$store.getters.IMAGES
		this.letterSpacing = this.$store.getters.LETTER_SPACING
		this.fontStyle = this.$store.getters.FONT_STYLE
	},
}
</script>
