<template>
	<div
		id="app"
    data-server-rendered="true"
		:class="[
			{ 'no-scroll': getBlockScroll },
			{
				[`${getFontSize} ${getColor} ${getImages} ${getLetterSpacing} ${getFontStyle} visually-impaired__content`]: getVisuallyImpaired,
			},
		]"
	>
		<Header></Header>
		<router-view />
		<Footer></Footer>
		<Modal></Modal>
		<CookieBanner />
	</div>
</template>

<script>
import { disablePageScroll, enablePageScroll } from 'scroll-lock'

import Header from '@/components/Header.vue'
import Footer from '@/components/Footer.vue'
import Modal from '@/components/Modal.vue'
import CookieBanner from './components/CookieBanner.vue'

const body = document.querySelector('body')

export default {
	components: {
    Header,
    Footer,
    Modal,
    CookieBanner
},

	computed: {
		getBlockScroll() {
			const status = this.$store.getters.BLOCK_SCROLL

			if (status) {
				disablePageScroll(body)
			} else {
				enablePageScroll(body)
			}

			return status
		},

		getVisuallyImpaired() {
			return parseInt(this.$store.getters.VISUALLY_IMPAIRED)
		},

		getFontSize() {
			const size = this.$store.getters.FONT_SIZE
			return `font-size--${size}`
		},

		getColor() {
			const color = this.$store.getters.COLOR
			return `color-type--${color}`
		},

		getImages() {
			const images = this.$store.getters.IMAGES

			if (images) {
				return ''
			} else {
				return 'hide-images'
			}
		},

		getLetterSpacing() {
			const letterSpacing = this.$store.getters.LETTER_SPACING
			return `letter-spacing--${letterSpacing}`
		},

		getFontStyle() {
			const style = this.$store.getters.FONT_STYLE
			return style
		},
	},

	mounted() {
		this.$store.dispatch('GET_SETTING')
		this.$store.dispatch('GET_CATEGORIES')

		const font = document.createElement("link");
		const font2 = document.createElement("link");
		const font3 = document.createElement("link");
		const font4 = document.createElement("link");

		font.setAttribute(
			"href",
			"/fontsP/GothamPro-Medium.woff"
		);
		font2.setAttribute(
			"href",
			"/fontsP/GothamPro.woff"
		);
		font3.setAttribute(
			"href",
			"/fontsP/GothamPro-Bold.woff"
		);
		font4.setAttribute(
			"href",
			"/fontsP/GothamPro-Light.woff"
		);
		font.setAttribute(
			"rel",
			"preload"
		)
		font2.setAttribute(
			"rel",
			"preload"
		)
		font3.setAttribute(
			"rel",
			"preload"
		)
		font4.setAttribute(
			"rel",
			"preload"
		)
		font.setAttribute(
			"as",
			"font"
		)
		font2.setAttribute(
			"as",
			"font"
		)
		font3.setAttribute(
			"as",
			"font"
		)
		font4.setAttribute(
			"as",
			"font"
		)
		font.preload = true;
		document.head.appendChild(font);
		document.head.appendChild(font2);
		document.head.appendChild(font3);
		document.head.appendChild(font4);
	},
}
</script>
