<template>
	<div class="modal__content" style="height: auto;">
		<h2 class="modal__title">Обратная связь</h2>
		<perfect-scrollbar class="modal__scroll modal__scroll--indent-scroll" :options="scrollbarOptions">
			<ValidationObserver
				ref="observer"
				@submit.prevent="validateForm()"
				tag="form"
				class="form"
				:style="formStyle"
			>
				<fieldset class="form__fieldset">
					<div class="flex flex--between">
						<label class="form__label">
							<span class="visually-hidden">Имя</span>
							<validation-provider
								mode="passive"
								:rules="{ required: true, min: 2, max: 50, alpharus: true }"
								v-slot="{ errors }"
								name="name"
							>
								<input
									type="text"
									class="form__input form__input--name"
									placeholder="Имя*"
									autocomplete="false"
									autocorrect="false" 
									spellcheck="false"
									@focus="($event)=>{
										/*
										$event.target.readOnly = false
										setTimeout(()=>{
											$event.target.readOnly = true
										}, 1000)
										
										if ($event.target.hasAttribute('readonly')) {
											$event.target.removeAttribute('readonly')
											$event.target.blur();    $event.target.focus()
										}
										*/
									}"
									v-model.trim="name"
									@blur="events('name') + (clickSend ? validateInput() : '')"
									@input="query('name') + (clickSend ? validateInput() : '')"
									@keydown="query('name')"
								/>
								<span class="form__input-helper text-red">{{ errors[0] }}</span>
							</validation-provider>
							<transition name="filter__slide">
								<perfect-scrollbar
									class="filter__box"
									v-if="suggestionsName.name && queryNameList"
								>
									<div class="filter__list">
										<button
											class="filter__item"
											type="button"
											v-for="(item, index) in suggestionsName.name"
											v-show="item.data.gender !== 'UNKNOWN'"
											:key="index"
											@click="
												setName(item.value) + (clickSend ? validateInput() : '')
											"
										>
											{{ item.value }}
										</button>
									</div>
								</perfect-scrollbar>
							</transition>
						</label>
						<label class="form__label">
							<span class="visually-hidden">Фамилия</span>
							<validation-provider
								mode="passive"
								:rules="{ required: true, min: 2, max: 50, alpharus: true }"
								v-slot="{ errors }"
								name="lastname"
							>
								<input
									type="text"
									class="form__input"
									placeholder="Фамилия*"
									v-model.trim="lastname"
									@blur="
										events('lastname') + (clickSend ? validateInput() : '')
									"
									@input="
										query('lastname') + (clickSend ? validateInput() : '')
									"
								/>
								<span class="form__input-helper text-red">{{ errors[0] }}</span>
							</validation-provider>

							<transition name="filter__slide">
								<perfect-scrollbar
									class="filter__box"
									v-if="suggestionsName.lastname && querylastnameList"
								>
									<div class="filter__list">
										<button
											class="filter__item"
											type="button"
											v-for="(item, index) in suggestionsName.lastname"
											v-show="item.data.gender !== 'UNKNOWN'"
											:key="index"
											@click="
												setLastname(item.value) +
													(clickSend ? validateInput() : '')
											"
										>
											{{ item.value }}
										</button>
									</div>
								</perfect-scrollbar>
							</transition>
						</label>
					</div>
				</fieldset>

				<fieldset class="form__fieldset">
					<div class="flex">
						<label class="checkbox">
							<input
								type="checkbox"
								class="visually-hidden"
								v-model="answer_method_0"
								@change="selectNoAnswer"
							/>
							<span class="checkbox__indicator"></span>
							<span class="checkbox__text">Ответ не требуется</span>
						</label>
					</div>
				</fieldset>

				<fieldset class="form__fieldset">
					<div class="flex flex--between">
						<label class="form__label">
							<span class="visually-hidden">Мобильный телефон</span>
							<validation-provider
								mode="passive"
								:rules="{ required: !answer_method_0, length: 17 }"
								v-slot="{ errors }"
								name="mobilePhone"
							>
								<input
									type="text"
									class="form__input"
									:placeholder="phoneText"
									:disabled="answer_method_0"
									v-mask="'8 (9##) ### ## ##'"
									v-model.trim="phone"
									@input="clickSend ? validateInput() : ''"
									@blur="clickSend ? validateInput() : ''"
								/>
								<span class="form__input-helper text-red">{{ errors[0] }}</span>
							</validation-provider>
						</label>
						<label class="form__label">
							<span class="visually-hidden">Электронная почта</span>
							<validation-provider
								mode="passive"
								:rules="{ required: answer_method_2, email: true }"
								v-slot="{ errors }"
								name="email"
							>
								<input
									type="text"
									class="form__input"
									:placeholder="emailText"
									:disabled="answer_method_0"
									:value="email ? email.toLowerCase().replaceAll(/\s/g,''): null"
									@input="($event)=>{email = $event.target.value.toLowerCase(); return clickSend ? validateInput() : ''}"
									@blur="setEmail($event.target.value) + (clickSend ? validateInput() : '')"
								/>
								<span class="form__input-helper text-red">{{ errors[0] }}</span>
							</validation-provider>
						</label>
					</div>
				</fieldset>

			    <div class="form__input--container">
					<validation-provider
						v-if="subjects"
						mode="passive"
						:rules="{ required: true }"
						v-slot="{ errors }"
						name="Subjects"
					>
						<Select
							class="form__select"
							:searchable="false"
							:options="subjects"
							v-model="selectedSubjects"
							@input="clickSend ? validateInput() : ''"
							@blur="clickSend ? validateInput() : ''"
							placeholder="Тема обращения*"
						>
							<div slot="no-options">Ничего не найдено</div>
							<template #open-indicator="{ attributes }">
								<selectArrow v-bind="attributes"></selectArrow>
							</template>
							<template #search="{attributes, events}">
								<input class="vs__search" v-bind="attributes" v-on="events" />
							</template>
						</Select>
						<span
							class="form__input-helper text-red"
							>{{ errors[0] }}</span
						>
						<!--style="bottom:-19px;"-->
					</validation-provider>
				</div>
				<!--<label class="form__label form__label--full">-->
				<div class="form__input--container">
					<validation-provider
						mode="passive"
						:rules="{ required: true, max: 2000, min: 10 }"
						v-slot="{ errors }"
						name="body"
					>
						<textarea
							cols="30"
							class="form__input form__input--textarea"
							placeholder="Сообщение*"
							v-model="body"
							@input="clickSend ? validateInput() : ''"
							@blur="clickSend ? validateInput() : ''"
							style="margin-top:10px;margin-bottom:10px;max-height:120px;min-height:120px;resize:none;"
						></textarea>
						<span class="form__input-helper text-red" style="">{{ errors[0] }}</span>
					</validation-provider>
				</div>
				<!--</label>-->

				<div class="form__file text-center flex flex--column flex--middle">
					<label>
						<input
							type="file"
							class="visually-hidden"
							multiple
							:accept="fileAccept"
							@change="loadFiles"
							ref="fileInput"
						/>
						<span class="form__file-text" style="text-decoration: none;"
							:title="`Не более чем ${fileAmount}, общий размер файлов не должен превышать ${fileSize} Мбайт форматов ${fileAccept.replaceAll('.', '')}`"
						>
							Прикрепить файл
							<span class="icon icon--clip"></span>
						</span>
					</label>
					<div class="form__filelist">
						<div class="form__file" v-for="(file, index) in files" :key="index">
							<span class="form__file_name" :title="file.name">{{ file.name }}</span>
							<span class="form__file_delete" @click="files.splice(index, 1)"></span>
						</div>
					</div>
					<span
						class="form__input-helper text-red"
						v-if="filesError"
						v-html="filesErrorText"
					></span>
				</div>

                <div class="flex flex--column flex--middle form__footer">
                    <div style="position: relative;">
                        <label class="checkbox">
                            <input type="checkbox"
                                   class="visually-hidden"
                                   v-model="agreement"
                                   @click="agreement ? validateInput() : ''" />

                            <span class="checkbox__indicator" style="margin-top:7px;"></span>
                            <!--@click.native="closeModal"-->
                            <span class="checkbox__text" style="padding-top:7px;">
                                Я принимаю
                                <router-link to="/holder/capabilities/soglasie"
                                             class="link"
                                             target="_blank">соглашение</router-link>
                                об использовании персональных данных
                            </span>
                        </label>
                        <span v-show="!agreement && clickSend" class="form__input-helper text-red">
                            {{
							agreementError
                            }}
                        </span>
                    </div>

                    <div class="smart-captcha" id="container" style="margin: 8px auto 16px;"></div>
                    
                    <button class="button"
                            type="submit"
                            :disabled="response && response.awaitResponse"
                            :class="{
							'animated-background': response && response.awaitResponse,
						}"
                            @click="clickSend = true">
                        Отправить
                    </button>
                    <br />
                </div>
			</ValidationObserver>
		</perfect-scrollbar>
	</div>
</template>

<script>
import { required, minLength, email } from 'vuelidate/lib/validators'
import Select from 'vue-select'
import 'vue-select/dist/vue-select.css'

import selectArrow from '@/assets/images/icons/icon_arrow-select.svg?inline'
import {
	ValidationObserver,
	ValidationProvider,
	extend,
	localize,
} from 'vee-validate'
import * as rules from 'vee-validate/dist/rules'
localize('ru')
		/* {
			min:
				`Минимальная длина: ${length} ` + (length > 4 ? 'символов' : 'символа'),
			max:
				`Максимальная длина: ${length} ` + (length > 4 ? 'символов' : 'символа'),
		*/
localize({
	ru: {
		messages: {
			min:`Минимальная длина: 10 символов`,
			max:`Максимальная длина: 2024 символа`,
			alpharus: `Разрешены только русские буквы`,
			length: `Поле может состоять только из ${length} чисел`,
			required: 'Поле обязательно для заполнения',
			email: 'Значение не является электронной почтой',
		},
		fields: {
			name: {
				min: `Минимальная длина: 2 символа`,
				max: `Минимальная длина: 50 сиволов`,
			},
			lastname: {
				min: `Минимальная длина: 2 символа`,
				max: `Минимальная длина: 50 сиволов`,
			},
			agreement: {
				is: `Поле обязательно для заполнения`,
			},
			mobilePhone: {
				length: 'Значение не является телефоном',
				required: 'Поле обязательно для заполнения',
			},
		},
	},
})
Object.keys(rules).forEach(rule => {
	extend(rule, rules[rule])
})

// with typescript
for (let [rule, validation] of Object.entries(rules)) {
	extend(rule, {
		...validation,
	})
}
extend('alpharus', {
	validate(value) {
		return /^[А-яЁё\s-]*$/i.test(value)
	},
	message: 'Разрешены только русские буквы',
})

export default {
	name: 'ModalFeedback',

	components: {
		Select,
		selectArrow,
		ValidationObserver,
		ValidationProvider,
	},

	data() {
		return {
			clickSend: false,
			selectedSubjects: null,
            scrollbarOptions: {},
			body: null,

			files: new Array(),

			answer_method_0: true,
			answer_method_1: false,
			answer_method_2: false,
			answer_method: 0,

			name: null,
			nameError: '',

			lastname: null,
			lastnameError: '',

			phone: null,
			phoneText: 'Мобильный телефон',

			email: null,
			emailText: 'Электронная почта',

			agreement: false,
			agreementError: 'Поле обязательно для заполнения',

			error: false,

			fileAmount: 10,
			fileSize: 10,
			fileAccept: '.pdf, .png, .jpg, .jpeg, .jpe, .jfif',
			fileNameSize: 200,
			filesError: false,
			filesErrorText: '',
            
            ratio: 1,
            formStyle: '',
			queryNameList: false,
			querylastnameList: false,

			attributes: {
				ref: 'openIndicator',
				role: 'presentation',
				class: 'vs__open-indicator',
			},

			smartCaptchaWidgetId: null,
		}
	},
	watch: {

		name(){
			if(this.name.length >=30){
				this.name = this.name.slice(0, 30)
			}
		},
		lastname(){
			if(this.lastname.length >=30){
				this.lastname = this.lastname.slice(0, 30)
			}
		},
		ratio(r){
			if(r < 1.25)
				this.formStyle = ''
			else if(r >=1.4 )
				this.formStyle = 'height:450px;overflow-y:scroll;'
			else
				this.formStyle = 'height:550px;overflow-y:scroll;'
			 
		},
	},
	methods: {
		resizeScreen() {
			//const size = e.target
			if(window.innerWidth >= 1024){
				this.ratio = window.devicePixelRatio || window.screen.availWidth / document.documentElement.clientWidth
				this.scrollbarOptions = {
					suppressScrollY: true, 
					useBothWheelAxes: false
				}
			} else {
				this.ratio = 1
				this.scrollbarOptions = {}
			}
		},
		validateInput() {
			setTimeout(() => {
				this.$refs.observer.validate()
			}, 150)
		},

		setEmail(value) {
			this.email = value

			if (this.answer_method_2 && value.length > 0) {
				this.$v.email.$touch()
			} else {
				if (value.length > 0) {
					this.$v.email.$touch()
				} else {
					this.$v.email.$reset()
				}
			}
		},

		setPhone(value) {
			this.phone = value

			if (this.answer_method_1) {
				this.$v.phone.$touch()
			} else {
				if (value.length > 0) {
					this.$v.phone.$touch()
				} else {
					this.$v.phone.$reset()
				}
			}
		},

		async validateForm() {
			const isValid = await this.$refs.observer.validate()
			if (!isValid) {
				return false
			}
			if (!this.agreement) {
				return false
			}
			let token = window.smartCaptcha.getResponse(this.smartCaptchaWidgetId)
			if (!token) {
                return false
            }
            this.pushFeedback(token)
		},
		pushFeedback(token) {
			this.$metrika.reachGoal('feedbackForm_Sent')
			/*  */
			if (
				!this.$v.body.$error &&
				!this.$v.name.$error &&
				!this.$v.lastname.$error &&
				!this.$v.phone.$error &&
				!this.$v.email.$error &&
				!this.filesError
			) {
				const FD = new FormData()

				FD.set('subject', this.selectedSubjects.label || '')
				FD.set('subject_id', this.selectedSubjects.id || '')
				FD.set('body', this.body.replace(/(\r\n|\n|\r)/gm, '') || '')
				FD.set('answer_method', this.answer_method)
				FD.set('name', this.name || '')
				FD.set('lastname', this.lastname || '')
				if (this.phone && this.phone.length)
					FD.set('phone', this.phone.replace(/[\s-()]/g, '').substr(1))
				if (this.email && this.email.length)
					FD.set('email', this.email)
				FD.set('token', token || '')

				if (this.files.length) 
					for (let file of this.files)
						FD.append('file[]', file)

				this.$store.dispatch('PUSH_FEEDBACK', FD)
				this.$store.dispatch('PUSH_FEEDBACK_ESK', FD)
				this.$store.dispatch('SET_FEEDBACK_STATUS', {
					awaitResponse: true,
				})
				this.$store.dispatch('SET_FEEDBACK_STATUS_ESK', {
					awaitResponse: true,
				})
				this.$store.commit('SET_FEEDBACK_NEED_ANSWER', this.answer_method !== 0)
			}
		},

		loadFiles(e) {
			window.__dbg_files = this.files;
			const files = e.target.files
			this.filesErrorText = ''
			this.filesError = false

			let fileNameLengthError = false
			let fileTypeError = false
			let fileSizeError = false
			let fileCountError = false

			let filesSize = this.files.reduce((acc, file) => acc += file.size / 1000000, 0)

			for (let file of files) {
				if (this.files.length >= this.fileAmount) {
					fileCountError = true
					break
				}

				if (file.name.length > 200) {
					fileNameLengthError = true
					continue
				}

				if (
					file.type != 'image/png' &&
					file.type != 'image/jpeg' &&
					file.type != 'image/jpg' &&
					file.type != 'image/jpe' &&
					file.type != 'image/jfif' &&
					file.type != 'application/pdf'
				) {
					fileTypeError = true
					continue
				}

				let currFileSize = file.size / 1000000
				if (filesSize + currFileSize > this.fileSize) {
					fileSizeError = true
					continue
				}

				filesSize += currFileSize
				this.files.push(file)
			}

			let errorText = ''
			if (fileCountError)
				errorText += `Общее количество файлов не должно превышать ${this.fileAmount}</br>`
			if (fileNameLengthError)
				errorText += 'Имя файла превышает 200 символов</br>'
			if (fileTypeError)
				errorText += `Загрузка файлов разрешена только следующих форматов: ${ this.fileAccept.replaceAll('.', '') }</br>`
			if (fileSizeError)
				errorText += `Общий размер файлов не должен превышать ${this.fileSize}МБ</br>`

			if (errorText.length > 0)
				this.showErrorToast(errorText)
		},

		selectSubject(value) {
			this.selectedSubjects = value
		},

		selectAnswer() {
			if (this.answer_method_2) {
				this.answer_method_0 = false
				this.answer_method = 2
				this.emailText = 'Электронная почта*'
			} else {
				this.answer_method_0 = true
				this.answer_method = 0
				this.emailText = 'Электронная почта'
			}
		},

		selectNoAnswer() {
			if (this.answer_method_0) {
				this.answer_method_2 = false
				this.answer_method = 0
				this.emailText = 'Электронная почта'
			} else {
				this.answer_method_2 = true
				this.answer_method = 2
				this.emailText = 'Электронная почта*'
			}
		},

		events(data) {
			if (data == 'name') {
				data = {
					value: 'name',
					type: 'name',
					list: 'queryNameList',
				}
			} else if (data == 'lastname') {
				data = {
					value: 'lastname',
					type: 'name',
					list: 'querylastnameList',
				}
			}
			this[data.list] = false

			if (this[data.value] && this[data.value].length > 0) {
				this.$v[data.value].$touch()
			}

			setTimeout(() => {
				this.$store.commit('SET_DADATA', { type: data.type, data: null })
			}, 100)
		},

		query(data) {
			if (data == 'name') {
				data = {
					value: this.name,
					list: 'queryNameList',
					type: 'name',
					query: 'fio',
				}
			} else if (data == 'lastname') {
				data = {
					value: this.lastname,
					list: 'querylastnameList',
					type: 'lastname',
					query: 'fio',
				}
			}

			const options = {
				count: 30,
				from_bound: {},
				query: data.value,
				to_bound: {},
			}

			if (data.value && data.value.length > 0) {
				this[data.list] = true
				this.$store.dispatch('GET_DADATA', {
					type: data.type,
					data: options,
					query: data.query,
				}).then((e)=>{
					console.log(e)
				})
			} else {
				this[data.list] = false
				this.$store.commit('SET_DADATA', { type: data.type, data: null })
			}
		},

		setName(value) {
			this.name = value
		},

		setLastname(value) {
			this.lastname = value
		},

		switchModal() {
			this.$store.dispatch('GET_MODAL', { type: 5 })
		},

		setPhoneMask() {
			if (!this.phone) {
				this.phone = '8 (___) ___ __ __'
			}
		},

		closeModal() {
			this.$store.dispatch('GET_MODAL')
		},

		showErrorToast(message) {
			this.$toasted.show(message, {
				theme: 'toasted-primary',
				position: 'top-right',
				duration: 8000,
				keepOnHover: true,
				type: 'error',
			})
		},
	},

	computed: {
		subjects() {
			const subjects = this.$store.getters.LIST_FEEDBACK_THEMES

			if (subjects) {
				return subjects.map(item => {
					return (item = {
						label: item.name,
						id: item.id,
					})
				})
			} else {
				return null
			}
		},

		response() {
			const response = this.$store.getters.FEEDBACK_STATUS
			const responseEsk = this.$store.getters.FEEDBACK_STATUS_ESK

			let message = ''

			if (
				responseEsk &&
				responseEsk.awaitResponse == false &&
				response &&
				response.awaitResponse == false &&
				response.success == true &&
				responseEsk.success == true
			) {
				this.switchModal()
			} else if (
				responseEsk &&
				responseEsk.awaitResponse == false &&
				response &&
				response.awaitResponse == false &&
				(response.errors == true || responseEsk.errors == true)
			) {
				if (
					responseEsk &&
					responseEsk[0] &&
					responseEsk[0].message &&
					responseEsk.errors == true
				) {
					message = responseEsk[0].message.replace('email', 'Email')
					message = message.replace('phone', 'Телефон')
					message = message.replace('body', 'Сообщение')
					message = message.replace('name', 'Имя')
					message = message.replace('lastname', 'Фамилия')
				} else {
					message =
						'Ваше сообщение не было отправлено, пожалуйста, повторите попытку позже'
				}

				this.showErrorToast(message)
			}

			return response
		},

		suggestionsName() {
			return this.$store.getters.DADATA
		},
	},

	validations() {
		let phone = {
			minLength: minLength(18),
		}
		///^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i
        const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/i
		let setEmail = {
			email,
			alpha: val =>
				re.test(
					val.toLowerCase()
				),
		}

		if (this.answer_method_1) {
			phone = { ...phone, required }
		} else {
			if (phone.required) {
				delete phone.required
			}
		}

		if (this.answer_method_2) {
			setEmail = { ...setEmail, required }
		} else {
			if (email.required) {
				delete email.required
			}
		}

		return {
			body: {
				alpha: val => /^.{0,2000}$/i.test(val),
			},
			name: {
				required,
				alpha: val => /^[А-яЁё\s-]{2,50}$/i.test(val),
			},
			lastname: {
				required,
				alpha: val => /^[А-яЁё\s-]{2,50}$/i.test(val),
			},
			phone: phone,
			email: setEmail,
		}
	},

	created() {
		window.addEventListener("resize", this.resizeScreen);
		this.resizeScreen()

		setTimeout(()=>{
let element = document.querySelector('.form__input--name')//('input[name=name]');
element.addEventListener('input', updateValue);
function updateValue(e) {
  //log.textContent = e.target.value;
  if(e.target.value.length === 2){
	setTimeout(()=>{
		//e.target.value += ' '
		element.dispatchEvent(new KeyboardEvent('keydown', {keyCode: 32}))
		element.dispatchEvent(new KeyboardEvent('keydown', {keyCode: 8}))
	}, 2000)
    setTimeout(()=>{
      	e.target.value = e.target.value.trim()
      	e.target.value = e.target.value.replaceAll(/\s/g,'')
    }, 4000)
	/*
	e.target.readOnly = true
	setTimeout(()=>{
		e.target.readOnly = false
		e.target.focus()
	}, 1000)
	*/
	/*
	setTimeout(()=>{
		e.target.value += ' '
		element.dispatchEvent(new KeyboardEvent('keydown', {keyCode: 32}))
		element.dispatchEvent(new KeyboardEvent('keydown', {keyCode: 8}))
	}, 1000)
    setTimeout(()=>{
      	e.target.value = e.target.value.trim()
      	e.target.value = e.target.value.replaceAll(/\s/g,'')
		element.dispatchEvent(new KeyboardEvent('keydown', {keyCode: 32}))
		element.dispatchEvent(new KeyboardEvent('keydown', {keyCode: 8}))
    }, 2000)
	*/
  }
}
		}, 2000)
	},
	destroyed() {
		window.removeEventListener("resize", this.resizeScreen);
	},

	mounted() {
		this.$store.dispatch('GET_LIST_FEEDBACK_THEMES')

        if (window.smartCaptcha) {
            this.smartCaptchaWidgetId = window.smartCaptcha.render('container', {
                sitekey: process.env.VUE_APP_RECAPTCHA_KEY
			});
        }
	},

	beforeDestroy() {
		this.$store.dispatch('SET_FEEDBACK_STATUS', null)
		this.$store.dispatch('SET_FEEDBACK_STATUS_ESK', null)
	},
}
</script>
<style>
.grecaptcha-badge{
	position:relative!important;
	bottom:initial!important;
	right:initial!important;
	margin-top:12px;
}
.modal__box .form__fieldset {
  margin-bottom: 10px;
}
.modal__box .form__fieldset .checkbox {
  margin-bottom: 0;
}
@media only screen and (min-width: 1024px) and (orientation: portrait) {
	.modal__box .modal__content .modal__scroll{
		max-height: calc(79vh - 160px);
		overflow-y: scroll!important;
	}
}
@media only screen and (min-width: 768px) and (max-width: 950px)  and (orientation: portrait) {
	.modal__box{
		min-height: 641px;
	}
	.modal__box .modal__content{
		min-height: 551px;
	}
	.modal__box .modal__content .modal__scroll{
		height: calc(80vh - 190px);
	}
}
.form__input--textarea{
    max-height: 120px!important;
	min-height: 120px;
}

.form__filelist {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	width: 100%;
}
.form__filelist .form__file {
	display: flex;;
    flex: 0 0 45%;
    width: 45%;
	justify-content: space-between;
	align-items: center;
	padding: 4px 8px;
	margin: 0;
}
.form__filelist .form__file .form__file_name {
	display: inline-block;
	width: 75%;
	overflow: hidden;
	text-wrap: nowrap;
	text-overflow: ellipsis;
}
.form__filelist .form__file .form__file_delete {
	display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
	cursor: pointer;
}
.form__filelist .form__file .form__file_delete:before,
.form__filelist .form__file .form__file_delete:after {
  position: absolute;
  left: 7px;
  content: ' ';
  height: 17px;
  width: 2px;
  background-color: #333;
}
.form__filelist .form__file .form__file_delete:before {
  transform: rotate(45deg);
}
.form__filelist .form__file .form__file_delete:after {
  transform: rotate(-45deg);
}
</style>