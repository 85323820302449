const state = {
	modalActive: {
		active: false,
		type: null,
	},
}

const getters = {
	MODAL: state => state.modalActive,
}

const mutations = {
	SWITCH_MODALSTATUS: (state, payload) => {
		if (payload) {
			state.modalActive = {
				type: payload.type,
				active: state.modalActive.active,
			}
		}

		if (payload) {
			state.modalActive = {
				active: true,
				type: state.modalActive.type,
			}
		} else {
			state.modalActive = {
				active: false,
				type: state.modalActive.type,
			}
		}
	},
}

const actions = {
	GET_MODAL: (context, payload) => {
		context.commit('SWITCH_MODALSTATUS', payload)
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
