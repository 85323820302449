<template>
	<div class="flex flex--middle follow-us">
		<!--<a
			v-if="
				getSetting && getSetting.facebook && getSetting.facebook.value != ''
			"
			:href="getSetting.facebook.value"
			@click="facebookClick()"
			target="_blank"
			class="follow-us__link"
		>
			<span class="visually-hidden">
				{{ getSetting.facebook.description }}
			</span>
			<facebook />
		</a>-->
		<!--<a
			v-if="
				getSetting && getSetting.instagram && getSetting.instagram.value != ''
			"
			:href="getSetting.instagram.value"
			@click="instagramClick()"
			target="_blank"
			class="follow-us__link"
		>
			<span class="visually-hidden">
				{{ getSetting.instagram.description }}
			</span>
			<instagram />
		</a>-->
		<a
			v-if="getSetting && getSetting.vk && getSetting.vk.value != ''"
			:href="getSetting.vk.value"
			@click="vkClick()"
			target="_blank"
			class="follow-us__link"
		>
			<span class="visually-hidden">
				{{ getSetting.vk.description }}
			</span>
			<vk />
		</a>
		<a
			v-if="
				getSetting && getSetting.telegram && getSetting.telegram.value != ''
			"
			:href="getSetting.telegram.value"
			@click="telegramClick()"
			target="_blank"
			class="follow-us__link"
		>
			<span class="visually-hidden">
				{{ getSetting.telegram.description }}
			</span>
			<telegram />
		</a>
		<a
			v-if="
				getSetting &&
					getSetting.odnoklassniki &&
					getSetting.odnoklassniki.value != ''
			"
			:href="getSetting.odnoklassniki.value"
			@click="odnoklassnikiClick()"
			target="_blank"
			class="follow-us__link"
		>
			<span class="visually-hidden">
				{{ getSetting.odnoklassniki.description }}
			</span>
			<odnoklassniki />
		</a>
	</div>
</template>

<script>
//import facebook from '@/assets/images/icons/icon_facebook.svg?inline'
//import instagram from '@/assets/images/icons/icon_instagram.svg?inline'
import vk from '@/assets/images/icons/icon_vk.svg?inline'
import telegram from '@/assets/images/icons/icon_telegram.svg?inline'
import odnoklassniki from '@/assets/images/icons/icon_odnoklassniki.svg?inline'

export default {
	name: 'Social',

	components: {
		//facebook,
		//instagram,
		vk,
		telegram,
		odnoklassniki,
	},

	methods: {
		odnoklassnikiClick() {
			this.$metrika.reachGoal('clickOn_odnoklassniki')
		},

		telegramClick() {
			this.$metrika.reachGoal('clickOn_telegram')
		},

		//facebookClick() {
		//	this.$metrika.reachGoal('clickOn_Facebook')
		//},

		//instagramClick() {
		//	this.$metrika.reachGoal('clickOn_instagram')
		//},

		vkClick() {
			this.$metrika.reachGoal('clickOn_vk')
		},
	},

	computed: {
		getSetting() {
			return this.$store.getters.SETTING
		},
	},
}
</script>
