const defaultAppliedFilters = {
	all: true,
	list: [],
	cardType: [],
}

const state = {
	pointListFilters: null,
	pointListAppliedFilters: defaultAppliedFilters,
}

const getters = {
	POINT_LIST_FILTERS: state => state.pointListFilters,

	POINT_LIST_APPLIED_FILTERS: state => state.pointListAppliedFilters,
}

const mutations = {
	SET_POINT_LIST_FILTERS: (state, payload) => {
		state.pointListFilters = payload
	},

	SET_POINT_LIST_APPLIED_FILTERS: () => {
		/* в коде где-то может упоминаться, оставил "заглушку" */
	},
}

const actions = {
	SET_POINT_LIST_APPLIED_FILTERS: (context, payload) => {
		context.commit('SET_POINT_LIST_APPLIED_FILTERS', payload)
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
