import Axios from 'axios'

const state = {
	blocks: {
		HolderHome: null,
		Capabilities: null,
		HowToGetACard: null,
		Materials: null,
		PartnersProgram: null,
		Support: null,
		PartnerHome: null,
		Advantages: null,
		JoiningProcedure: null,
		PartnerMaterials: null,
		ContactsPartner: null,
		OperatorInformation: null,
		Cooperation: null,
		Contacts: null,
	},
}

const getters = {
	BLOCKS: state => state.blocks,
}

const mutations = {
	SET_BLOCKS: (state, payload) => {
		state.blocks[payload.page] = payload.data
	},
}

const actions = {
	GET_BLOCKS: async (context, payload) => {
		await Axios({
			url: `${process.env.VUE_APP_API_VERSION}/block?page=${payload.page}`,
			method: 'GET',
		})
			.then(response => {
				context.commit('SET_BLOCKS', {
					page: payload.block,
					data: response.data,
				})
			})
			.catch(err => {
				console.log(err)
			})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
