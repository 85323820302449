<template>
	<div class="modal__content modal_small__content flex flex--column flex--middle">
		<div class="modal__container flex flex--column flex--middle">
			<h2 class="modal__title text-big">Спасибо за обращение!</h2>

			<img
				:src="require('@/assets/images/icons/icon_modal-success.svg')"
				alt="Ваша заявка принята!"
				class="modal__icon"
			/>

			<p
				v-if="feedbackNeedAnswer"
				class="main__text text-center text-medium text-medium-size mb-50"
			>
				Мы свяжемся с Вами в ближайшее время
			</p>

			<p class="main__text text-center mb-60 modal__text-container">
				Присоединяйтесь к Единой социальной карте в социальных сетях
			</p>

			<SocialList class="follow-us--dark"></SocialList>
		</div>
	</div>
</template>

<script>
import SocialList from '@/components/Social.vue'

export default {
	name: 'ModalSuccessFeedback',

	components: {
		SocialList,
	},

	computed: {
		feedbackNeedAnswer() {
			return this.$store.getters.FEEDBACK_NEED_ANSWER
		},
	},
}
</script>
