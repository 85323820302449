import Axios from 'axios'

const state = {
	dadata: {
		name: null,
		lastname: null,
		patronymic: null,
		address: null,
		fms: null,
	},
}

const getters = {
	DADATA: state => state.dadata,
}

const mutations = {
	SET_DADATA: (state, payload) => {
		state.dadata[payload.type] = payload.data
	},
}

const actions = {
	GET_DADATA: async (context, payload) => {
		await Axios({
			url: `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/${payload.query}`,
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Token ${process.env.VUE_APP_DADATA_KEY}`,
			},
			data: payload.data,
		})
			.then(response => {
				context.commit('SET_DADATA', {
					type: payload.type,
					data: response.data.suggestions,
				})
			})
			.catch(err => {
				console.log(err)
			})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
