import Axios from 'axios'

const state = {
	search: null,
}

const getters = {
	SEARCH_RESULT: state => state.search,
}

const mutations = {
	SET_SEARCH_RESULT: (state, payload) => {
		if (state.search && !payload.refresh) {
			state.search.currentPage = payload.currentPage
			state.search.data = state.search.data.concat(payload.data)
		} else {
			state.search = payload
		}
	},
}

const actions = {
	GET_SEARCH: async (context, payload) => {
		await Axios({
			url: `${process.env.VUE_APP_API_VERSION}/search?query=${payload.query}&page=${payload.currentPage}&per-page=${payload.perPage}`,
			method: 'GET',
		})
			.then(response => {
				context.commit('SET_SEARCH_RESULT', {
					query: payload.query,
					data: response.data,
					pageCount: response.headers['x-pagination-page-count'],
					currentPage: response.headers['x-pagination-current-page'],
					total: response.headers['x-pagination-total-count'],
					refresh: payload.refresh,
				})
			})
			.catch(err => {
				console.log(err)
			})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
