import Axios from 'axios'

const state = {
	listAcceptors: null,
	acceptor: null,
}

const getters = {
	LIST_ACCEPTORS: state => state.listAcceptors,

	ACCEPTOR: state => state.acceptor,
}

const mutations = {
	SET_LIST_ACCEPTORS: (state, payload) => {
		state.listAcceptors = payload
	},

	SET_ACCEPTOR: (state, payload) => {
		state.acceptor = payload
	},
}

const actions = {
	GET_LIST_ACCEPTORS: async context => {
		await Axios({
			url: `${process.env.VUE_APP_API_ESK}/ListAcceptors`,
			method: 'GET',
			headers: {
				Authorization: process.env.VUE_APP_AUTH_ESK,
			},
		})
			.then(response => {
				context.commit('SET_LIST_ACCEPTORS', response.data)
			})
			.catch(err => {
				console.log(err)
			})
	},

	GET_ACCEPTOR: async (context, payload) => {
		context.commit('SET_ACCEPTOR', null)
		await Axios({
			url: `${process.env.VUE_APP_API_ESK}/AcceptorBranches?uid=${payload}`,
			method: 'GET',
			headers: {
				Authorization: process.env.VUE_APP_AUTH_ESK,
			},
		})
			.then(response => {
				context.commit('SET_ACCEPTOR', response.data)
			})
			.catch(err => {
				console.log(err)
			})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
