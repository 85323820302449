const state = {
	visuallyImpaired:
		localStorage.getItem('visuallyImpaired') != null
			? parseInt(localStorage.getItem('visuallyImpaired'))
			: 0,
	fontSize: localStorage.getItem('fontSize') || 'small',
	color: localStorage.getItem('switchColor') || 'normal',
	images:
		localStorage.getItem('switchImages') != null
			? parseInt(localStorage.getItem('switchImages'))
			: 1,
	letterSpacing: localStorage.getItem('letterSpacing') || 'normal',
	fontStyle: localStorage.getItem('fontStyle') || 'sans-serif',
}

const getters = {
	VISUALLY_IMPAIRED: state => state.visuallyImpaired,

	FONT_SIZE: state => state.fontSize,

	COLOR: state => state.color,

	IMAGES: state => state.images,

	LETTER_SPACING: state => state.letterSpacing,

	FONT_STYLE: state => state.fontStyle,
}

const mutations = {
	SWITCH_VISUALLY_IMPAIRED: (state, payload) => {
		localStorage.setItem('visuallyImpaired', payload)
		state.visuallyImpaired = payload
	},

	SWITCH_FONT_SIZE: (state, payload) => {
		localStorage.setItem('fontSize', payload)
		state.fontSize = payload
	},

	SWITCH_COLOR: (state, payload) => {
		localStorage.setItem('switchColor', payload)
		state.color = payload
	},

	SWITCH_IMAGES: (state, payload) => {
		localStorage.setItem('switchImages', payload)
		state.images = payload
	},

	SWITCH_LETTER_SPACING: (state, payload) => {
		localStorage.setItem('letterSpacing', payload)
		state.letterSpacing = payload
	},

	SWITCH_FONT_STYLE: (state, payload) => {
		localStorage.setItem('fontStyle', payload)
		state.fontStyle = payload
	},
}

const actions = {
	SET_VISUALLY_IMPAIRED: (context, payload) => {
		context.commit('SWITCH_VISUALLY_IMPAIRED', payload)
	},

	SET_FONT_SIZE: (context, payload) => {
		context.commit('SWITCH_FONT_SIZE', payload)
	},

	SET_COLOR: (context, payload) => {
		context.commit('SWITCH_COLOR', payload)
	},

	SET_IMAGES: (context, payload) => {
		context.commit('SWITCH_IMAGES', payload)
	},

	SET_LETTER_SPACING: (context, payload) => {
		context.commit('SWITCH_LETTER_SPACING', payload)
	},

	SET_FONT_STYLE: (context, payload) => {
		context.commit('SWITCH_FONT_STYLE', payload)
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
