import Axios from 'axios'

const state = {
	setting: null,
	categories: localStorage.getItem('categories')
		? JSON.parse(localStorage.getItem('categories'))
		: null,
}

const getters = {
	SETTING: state => state.setting,

	CATEGORIES: state => state.categories,
}

const mutations = {
	SET_SETTING: (state, payload) => {
		state.setting = new Object()

		if (payload && payload.length)
			payload.forEach(item => {
				state.setting[item.alias] = {
					title: item.title,
					value: item.value,
					description: item.description,
				}
			})
	},

	SET_CATEGORIES: (state, payload) => {
		state.categories = payload
		localStorage.setItem('categories', JSON.stringify(payload))
	},
}

const actions = {
	GET_SETTING: async context => {
		await Axios({
			url: `${process.env.VUE_APP_API_VERSION}/setting`,
			method: 'GET',
		})
			.then(response => {
				context.commit('SET_SETTING', response.data)
			})
			.catch(err => {
				console.log(err)
			})
	},

	GET_CATEGORIES: async context => {
		await Axios({
			url: `${process.env.VUE_APP_API_VERSION}/setting/categories`,
			method: 'GET',
		})
			.then(response => {
				if (
					JSON.stringify(context.categories) != JSON.stringify(response.data)
				) {
					context.commit('SET_CATEGORIES', response.data)
				}
			})
			.catch(err => {
				console.log(err)
			})
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
