<template>
	<div class="modal__content modal__pick-city">
		<h2 class="modal__title">Ваш город</h2>
		<p class="modal__title-text">
			Выбран:
			<span class="text-main">{{ pickedCity.name }}</span>
		</p>

		<form class="header__form" @submit.prevent="searchCity">
			<input
				type="text"
				placeholder="Поиск по городам"
				class="header__input"
				v-model="search"
				@input="handleInput"
			/>
			<!--<button type="submit" class="header__form-button">Найти</button>-->
		</form>

		<perfect-scrollbar class="modal__scroll modal__scroll--small">
			<template v-if="cities.length > 0">
				<ul
					class="modal__list"
					:class="{ 'modal__list--columns-1': cities.length <= 1 }"
				>
					<li class="modal__item" v-for="(item, index) in cities" :key="index">
						<h3 class="modal__item-title">{{ item.title }}</h3>
						<label
							class="modal__city-label"
							v-for="(city, index) in item.cities"
							:key="index"
						>
							<input
								type="radio"
								name="city"
								class="visually-hidden"
								:value="city"
								:data-citi-id="city.id"
								v-model="picked"
								@change="pickCity"
							/>
							<span class="modal__text">{{ city.name }}</span>
						</label>
					</li>
				</ul>
			</template>
			<template v-else>
				<p class="main__text text-center">Город не найден</p>
			</template>
		</perfect-scrollbar>
	</div>
</template>

<script>
export default {
	name: 'ModalCity',

	props: {
		data: {
			type: Array,
			required: true,
		},
	},

	data() {
		return {
			picked: this.$store.getters.PICKEDCITY,
			search: null,
			citiesList: this.data,
		}
	},

	methods: {
		pickCity() {
			this.$store.dispatch('SET_PICKEDCITY', this.picked)
			this.$store.dispatch('GET_MODAL', { type: 1 })
			this.$root.$emit('pickCity')
			this.$root.$emit('modal', 'close');
			localStorage.setItem('pickedCity', JSON.stringify(this.picked))
		},

		searchCity() {
			const str = this.search

			this.citiesList = this.data.filter(item =>
				item.name.match(new RegExp(`${str}`, 'gi'))
			)
		},
		handleInput(){
			this.searchCity();
		}
	},
	computed: {
		pickedCity() {
			return this.$store.getters.PICKEDCITY
		},

		cities() {
			//alert("computed:cities()")
			const data = this.citiesList
			let counter = 0
			let cities = new Array()
			let letter = ''

			data.forEach(item => {
				counter++
				const firstLetter = item.name.substr(0, 1)

				if (letter != firstLetter) {
					letter = firstLetter
					cities.push({ title: letter, cities: [] })
				}

				if (data.length == counter) {
					cities.forEach(item => {
						let t = item
						const letter = item.title

						data.forEach(item => {
							if (letter == item.name.substr(0, 1)) {
								t.cities.push({
									name: item.name,
									id: item.id,
									oktmo: item.oktmo,
									geolocation: item.geolocation,
								})
							}
						})
					})
				}
			})

			return cities
		},
	},
}
</script>

<style> /* Подбиралось на глаз, под капотом так же magic numbers @strokov_mm */

.modal__pick-city .modal__scroll {
	max-height: calc(100vh - 320px);
}

@media (max-width: 576px) {
	.modal__box {
		width: 100vw;
		height: 100vh;
		margin: 0;
		max-height: none;
	}

	.modal__pick-city {
		height: calc(100vh - 80px);
	}
	
	.modal__pick-city .modal__scroll {
		max-height: calc(100vh - 200px);
	}
}

</style>
