<template>
  <div v-if="!isCookiesAccepted" class="cookie-banner">
    <div class="description-container">
      Этот сайт использует cookie для хранения данных. Продолжая использовать этот сайт, вы даете согласие на работу с
      этими файлами.
    </div>
    <button class="button" @click="closeBanner">Принять и закрыть</button>
  </div>
</template>

<script>


export default {
  name: 'CookieBanner',

  computed: {
    isCookiesAccepted() {
      return this.$store.getters.COOKIES
    }
  },

  methods: {
    closeBanner() {
      this.$store.dispatch('ACCEPT_COOKIES', true)
      this.$cookies.set('eskso_cc', true, '6m', '', '', true, "None")
    }
  }
}
</script>
