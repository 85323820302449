<template>
	<div class="modal__content modal_small__content flex flex--column flex--middle">
		<div class="modal__container flex flex--column flex--middle">
			<h2 class="modal__title text-big">Ваша заявка принята!</h2>

			<img
				:src="require('@/assets/images/icons/icon_modal-success.svg')"
				alt="Ваша заявка принята!"
				class="modal__icon"
			/>

			<p class="main__text text-center mb-60">
				Теперь Вы можете
				<span class="text-medium text-underline"
					>выбрать пункт выдачи нефинансовых карт</span
				>, в котором Вам будет удобно забрать свою Единую социальную карту. Она
				будет готова в течение 15 минут.
			</p>

			<p class="main__text text-small text-center">
				Не забудьте взять с собой оригиналы документов, указанных в заявке.
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ModalSuccessRequest',
}
</script>
