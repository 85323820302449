<template>
	<footer class="footer" v-if="mainSite">
		<div class="container">
			<ul class="footer__list">
				<template v-if="links">
					<FooterItem
						:data="item"
						v-for="(item, index) in links"
						:key="index"
					></FooterItem>
				</template>

				<li class="footer__item">
					<!--<h3 class="footer__title">Официальное приложение</h3>
					<div class="flex">
						<a
							v-if="
								getSetting &&
									getSetting.appleapp &&
									getSetting.appleapp.value != ''
							"
							:href="getSetting.appleapp.value"
							class="footer__market"
						>
							<img
								src="@/assets/images/icons/icon_app-store.svg"
								alt="App store"
							/>
							<span class="visually-hidden">{{
								getSetting.appleapp.description
							}}</span>
						</a>
						<a
							v-if="
								getSetting &&
									getSetting.androidapp &&
									getSetting.androidapp.value != ''
							"
							:href="getSetting.androidapp.value"
							class="footer__market"
						>
							<img
								src="@/assets/images/icons/icon_google-play.svg"
								alt="Google play"
							/>
							<span class="visually-hidden">{{
								getSetting.androidapp.description
							}}</span>
						</a>
						<a
							v-if="
								getSetting &&
									getSetting.appgalleryapp &&
									getSetting.appgalleryapp.value != ''
							"
							:href="getSetting.appgalleryapp.value"
							class="footer__market"
						>
							<img
								src="@/assets/images/icons/icon_app-gallery.svg"
								alt="Huawei AppGallery"
							/>
							<span class="visually-hidden">{{
								getSetting.appgalleryapp.description
							}}</span>
						</a>
					</div>-->
					<SocialList></SocialList>
				</li>
			</ul>
			<div class="footer__copyright">
				<p class="footer__text">
					© 2020-2024. Все права защищены. ООО «САПФИР-ЭКСПЕРТ», ИНН 6672303952,
					ОГРН 1096672016870
				</p>
			</div>
		</div>
	</footer>
</template>

<script>
import FooterItem from '@/components/FooterItem.vue'
import SocialList from '@/components/Social.vue'

export default {
	name: 'Footer',

	components: {
		FooterItem,
		SocialList,
	},
	computed: {
		mainSite() {
			const meta = this.$route.meta

			if (meta.holder || meta.partner || meta.operator) {
				return true
			} else {
				return false
			}
		},

		getSetting() {
			let setting = this.$store.getters.SETTING
			//setting.push()

			if(setting && !setting['appgalleryapp']){
				setting['appgalleryapp'] = {
						alias: "appgalleryapp",
						created_at: 1586862491,
						description: "Ссылка на официальное приложение AppGallery",
						id: 6,
						order: 7,
						title: "Ссылка в AppGallery",
						title_editable: 0,
						type: "footer",
						updated_at: 1586862491,
						value: "https://appgallery.huawei.com/#/app/C104356703",
						_links: {
							self: {
								href: "/v1/setting/appgalleryaapp"
								}
						}
					}
			}
			return setting
		},

		links() {
			const links = this.$store.getters.SETTING

			if (links) {
				return [
					{
						title: 'Единая социальная карта',
						links: [
							{
								path: links.col_1_link_1.value,
								name: links.col_1_link_1.title,
							},
							{
								path: links.col_1_link_2.value,
								name: links.col_1_link_2.title,
							},
							{
								path: links.col_1_link_3.value,
								name: links.col_1_link_3.title,
							},
						],
					},
					{
						title: 'Поддержка',
						links: [
							{
								path: links.col_2_link_1.value,
								name: links.col_2_link_1.title,
							},
							{
								path: links.col_2_link_2.value,
								name: links.col_2_link_2.title,
							},
							{
								path: links.col_2_link_3.value,
								name: links.col_2_link_3.title,
							},
						],
					},
					{
						title: 'Оператор ЕСК',
						links: [
							{
								path: links.col_3_link_1.value,
								name: links.col_3_link_1.title,
							},
							{
								path: links.col_3_link_2.value,
								name: links.col_3_link_2.title,
							},
							{
								path: links.col_3_link_3.value,
								name: links.col_3_link_3.title,
							},
						],
					},
				]
			} else {
				return null
			}
		},
	},
}
</script>
