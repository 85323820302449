import Axios from 'axios'

const state = {
	listFilters: null,
	listAppliedAcceptorFilters: {
		all: true,
		list: [],
	},
	listAppliedStocksFilters: {
		all: true,
		list: [],
	},
	appliedCity: 'all',
}

const getters = {
	LIST_FILTERS: state => state.listFilters,

	LIST_APPLIED_ACCEPTOR_FILTERS: state => state.listAppliedAcceptorFilters,

	LIST_APPLIED_STOCKS_FILTERS: state => state.listAppliedStocksFilters,

	APPLIED_CITY: state => state.appliedCity,
}

const mutations = {
	SET_LIST_FILTERS: (state, payload) => {
		state.listFilters = payload
	},

	SET_LIST_APPLIED_ACCEPTOR_FILTERS: (state, payload) => {
		state.listAppliedAcceptorFilters = payload
	},

	SET_LIST_APPLIED_STOCKS_FILTERS: (state, payload) => {
		state.listAppliedStocksFilters = payload
	},

	SET_APPLIED_CITY: (state, payload) => {
		state.appliedCity = payload
	},
}

const actions = {
	GET_LIST_FILTERS: async context => {
		await Axios({
			url: `${process.env.VUE_APP_API_ESK}/ListAcceptorCategories`,
			method: 'GET',
			headers: {
				Authorization: process.env.VUE_APP_AUTH_ESK,
			},
		})
			.then(response => {
				context.commit('SET_LIST_FILTERS', response.data)
			})
			.catch(err => {
				console.log(err)
			})
	},

	SET_LIST_APPLIED_ACCEPTOR_FILTERS: (context, payload) => {
		context.commit('SET_LIST_APPLIED_ACCEPTOR_FILTERS', payload)
	},

	SET_LIST_APPLIED_STOCKS_FILTERS: (context, payload) => {
		context.commit('SET_LIST_APPLIED_STOCKS_FILTERS', payload)
	},

	SET_APPLIED_CITY: (context, payload) => {
		context.commit('SET_APPLIED_CITY', payload)
	},
}

export default {
	state,
	getters,
	mutations,
	actions,
}
